import { MenuItem, TextField, Typography } from "@mui/material";
import { TurqoiseButton, WhiteButton } from "../../styling";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";
import VisitMotivationTypesEnum, {
  VisitMotivationTypesEnum_toString
} from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import { useEffect, useState } from "react";
import { useCreateVisitMutation } from "common/services/VisitsService";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import VisitReasonsEnum from "common/enums/Calendaring/Visits/VisitReasonsEnum";
import { useNavigate } from "react-router-dom";
import { Alert_show } from "common/helpers/AlertHelper";
import ErrorComponent from "../ErrorComponent";
import { RootState, useAppDispatch } from "common/redux";
import FeatureFlags from "common/config/FeatureFlags";
import StartEncounterButton from "../../pages/MemberDetails/Header/StartEncounterButton";
import MemberType from "common/types/MemberType";
import { useSelector } from "react-redux";

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  member: MemberType;
}

const DATA = [
  // Disabling for rollout - only Device Setup & Appointment
  // VisitMotivationTypesEnum.DEVICE_ISSUE,
  // VisitMotivationTypesEnum.MISSED_APPT,
  // VisitMotivationTypesEnum.URGENT_ALERT
  VisitMotivationTypesEnum.DEVICE_SETUP,
  VisitMotivationTypesEnum.APPOINTMENT
];

const StartVisitModal = ({ isOpen, onRequestClose, member }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedReason, setSelectedReason] =
    useState<VisitMotivationTypesEnum>();

  const { data: staff } = useGetAuthenticatedUser();
  const { user } = useSelector((state: RootState) => state.auth);

  const [createVisitMutation, { data, isSuccess, error, isLoading }] =
    useCreateVisitMutation();

  const handleStartVisit = () => {
    createVisitMutation({
      body: {
        staff_id: staff.user.user_id,
        patient_id: member.patient.patient_id,
        motivation_reason: selectedReason,
        status: VisitStatusEnum.IN_PROGRESS,
        visit_type: VisitReasonsEnum.REMOTE_PATIENT_MONITORING
      },
      staff_id: staff.user.user_id,
      patient_id: member.patient.patient_id,
      with_care_flow: true
    });
  };

  useEffect(() => {
    if (isSuccess && data) {
      const { visit_id } = data;
      onRequestClose();
      navigate(`/visits/${visit_id}`);
    }
    if (error) {
      Alert_show({
        dispatch,
        title: "Could not create Encounter",
        // @ts-ignore
        content: <ErrorComponent error={error?.response?.data?.message} />
      });
    }
  }, [isSuccess, data, error]);

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Start Encounter Modal"
      modalHeight="500px"
      modalWidth="400px"
    >
      <ModalHeader onRequestClose={onRequestClose}>Start Encounter</ModalHeader>
      <ModalBody>
        <Typography variant="h6">
          Are you ready to begin the visit with the member?
        </Typography>
        <br />
        {FeatureFlags().CARE_FLOWS_START_ENCOUNTER_MODAL && (
          <>
            <Typography variant="h6">
              Select the reason for the visit
            </Typography>
            <br />
            <TextField
              label={"Reason"}
              select={true}
              slotProps={{
                select: {
                  variant: "outlined",
                  MenuProps: { PaperProps: { sx: { maxHeight: 200 } } },
                  onChange: (event) => {
                    setSelectedReason(
                      event.target.value as VisitMotivationTypesEnum
                    );
                  }
                }
              }}
              placeholder={"Enter Reason"}
              fullWidth={true}
            >
              {DATA.map((item) => (
                <MenuItem key={item} value={item}>
                  {VisitMotivationTypesEnum_toString(item)}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
        {!FeatureFlags().CARE_FLOWS_START_ENCOUNTER_MODAL && (
          <>
            <Typography variant="h6">
              Click Start Encounter to create the appointment in Athena.
            </Typography>
            <br />
            {FeatureFlags(user?.user_id).CARE_FLOWS && (
              <Typography variant="caption">
                If you are participating in the Arc of Call pilot program and
                this is a scheduled appointment, please click Start from an
                appointment either on a Schedule or the Appointments tab.
              </Typography>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          {FeatureFlags().CARE_FLOWS_START_ENCOUNTER_MODAL && (
            <TurqoiseButton
              loading={isLoading}
              disabled={!staff || !selectedReason}
              onClick={handleStartVisit}
            >
              Start Encounter
            </TurqoiseButton>
          )}
          {!FeatureFlags().CARE_FLOWS_START_ENCOUNTER_MODAL && (
            <StartEncounterButton patient={member} />
          )}
          <WhiteButton onClick={onRequestClose}>Cancel</WhiteButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

export default StartVisitModal;
