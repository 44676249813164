import { useMemo, useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import { DateTime } from "luxon";

import Table from "../../../components/Table/Table";
import ErrorComponent from "../../../components/ErrorComponent";
import useSanitizedParams from "../../../hooks/useSanitizedParams";
import { checkIdValid, isFalsy } from "common/helpers/helpers";
import DeleteEncounterConfirmationModal from "../../MemberDetails/DeleteEncounterConfirmationModal";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { SubmitEncounterModal } from "../../MemberDetails/Header/SubmitEncounter/SubmitEncounterModal";
import { NewProviderEncounterReasons } from "common/helpers/EncounterHelper";
import DropdownDateRangePicker from "../../../components/DropdownDateRangePicker";
import { useGetEncountersQuery } from "common/services/EncountersService";
import EncounterLinkedEntitiesEnum from "common/enums/EncounterLinkedEntitiesEnum";
import { useNavigate } from "react-router-dom";
// import { useGetEncounterVisitsQuery } from "common/services/VisitsService";

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 6px 0px;
`;

const encounterColumns = [
  { name: "encounterDurationEditable", size: 250, editable: true },
  { name: "encounterDateV2", size: 250 },
  { name: "encounterMember", size: 250 },
  { name: "encounterActions", size: 250 }
];

const EncountersTab = () => {
  const navigate = useNavigate();
  const params = useSanitizedParams();
  const { staffId } = params;
  const isValidId = checkIdValid(staffId);
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [editPastEncounterModalVisible, setEditPastEncounterModalVisible] =
    useState<boolean>(false);

  const [selectedEncounter, setSelectedEncounter] = useState(undefined);

  const setEditModal = (visible: boolean) => {
    if (!visible) setSelectedEncounter(undefined);
    setEditPastEncounterModalVisible(visible);
  };

  const [startsAfter, setStartsAfter] = useState<DateTime>(
    DateTime.now().minus({ days: 7 }).startOf("day")
  );
  const [startsBefore, setStartsBefore] = useState<DateTime>(
    DateTime.now().endOf("day")
  );

  const { data: encounters, isFetching: encounterVisitsIsFetching } =
    useGetEncountersQuery(
      // useGetEncounterVisitsQuery(
      {
        submitted_by: staffId,
        startsAfter,
        startsBefore,
        linked_entities: [EncounterLinkedEntitiesEnum.CARE_FLOW]
        // param for encounter-visit endpoint - comment this back in when we are ready to use thisparam for new endpoint
        // encounter_starts_on_after: startsAfter,
        // encounter_starts_on_before: startsBefore
      },
      { skip: isValidId === false }
    );

  const totalDuration = useMemo(() => {
    return encounters?.reduce((partialSum, encounter) => {
      const time =
        // value for encounter-visit endpoint - comment this back in when we are ready to use thisparam for new endpoint
        // encounter?.visit?.total_time ??
        encounter?.encounter?.duration;
      return partialSum + (time || 0);
    }, 0);
  }, [encounters]);

  const [selectedEncounterDeleteModal, setSelectedEncounterDeleteModal] =
    useState<string>();

  const handleDeleteEncounter = (encounter_id: string) => {
    setSelectedEncounterDeleteModal(encounter_id);
  };

  if (!isValidId)
    return <ErrorComponent error={`Invalid Member ID ${staffId}`} />;

  return (
    <>
      <Row>
        <Typography flex={1} variant={"h3"} color="black">
          Encounters History
        </Typography>

        <DropdownDateRangePicker
          onEndDateChange={setStartsBefore}
          onStartDateChange={setStartsAfter}
          endDate={startsBefore}
          startDate={startsAfter}
        />
      </Row>

      {!isFalsy(totalDuration) && (
        <Typography variant="body1" color="black">
          Total Submitted: {totalDuration} minutes
        </Typography>
      )}

      {encounterVisitsIsFetching && (
        <Box mt={"24px"}>
          <LoadingFallback count={10} />
        </Box>
      )}

      {encounters && !encounterVisitsIsFetching && (
        <Table
          tableColumns={encounterColumns}
          data={encounters}
          tableProps={{
            navigate,
            handleDeleteEncounter,
            currentRole,
            setSelectedEncounter,
            setEditPastEncounterModalVisible
          }}
        />
      )}

      {/* We are only using this for NP encounters right now */}
      <SubmitEncounterModal
        key={`editEncounter-${selectedEncounter?.encounter_id}`}
        modalOpen={editPastEncounterModalVisible && !isFalsy(selectedEncounter)}
        setModalOpen={setEditModal}
        memberId={undefined}
        isProvider={true}
        encounterReasons={NewProviderEncounterReasons}
        memberName={selectedEncounter?.member_name}
        default_total_time={selectedEncounter?.total_time}
        default_visit_type={selectedEncounter?.visit_type}
        default_modality={selectedEncounter?.modality}
        default_complexity={selectedEncounter?.complexity}
        selected_encounter_id={selectedEncounter?.encounter_id}
        hasProviderSubmittedEncounter={true}
        hasInvalidProviderAssigned={false}
      />

      <DeleteEncounterConfirmationModal
        isOpen={selectedEncounterDeleteModal !== undefined}
        onRequestClose={() => setSelectedEncounterDeleteModal(undefined)}
        encounterId={selectedEncounterDeleteModal}
      />
    </>
  );
};

export default EncountersTab;
