import { CareFlowResponseType } from "common/types/Visits/CareFlowResponseType";

const getField = async (careFlow: CareFlowResponseType, fieldId: string) => {
  // Loop through fields recursively to find the field
  for (const section of careFlow.sections) {
    for (const subsection of section?.subsections) {
      for (const field of subsection?.fields) {
        if (fieldId === field.field_id) return field;
        if (field.subfields) {
          for (const subfield of field?.subfields) {
            if (fieldId === subfield?.field_id) return subfield;
            if (subfield.subfields) {
              for (const subsubfield of subfield?.subfields) {
                if (fieldId === subsubfield?.field_id) return subsubfield;
                if (subsubfield.subfields) {
                  for (const subsubsubfield of subsubfield?.subfields) {
                    if (fieldId === subsubsubfield.field_id)
                      return subsubsubfield;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // If found, return the value of that field, else return null
  return null;
};

export { getField };
