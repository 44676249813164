import { DateTime } from "luxon";

import MemberStatusEnum from "common/enums/MemberStatusEnum";
import UserStatusEnum from "common/enums/UserStatusEnum";
import RolesEnum, {
  StaffRoles,
  canSeeEncounters,
  canSeeMemberOrders,
  canSeeReadings
} from "common/enums/RolesEnum";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import MemberType from "common/types/MemberType";

import { TableColumn } from "../components/Table/TableTypes";
import DateFilterFieldEnum from "../enums/DateFilterFieldEnum";
import SurveyLinkedEntitiesEnum from "common/enums/SurveyLinkedEntitiesEnum";
import RouteComponentTypeEnum from "common/enums/RouteComponentTypeEnum";
import UserLinkedEntitiesEnum from "common/enums/UserLinkedEntitiesEnum";
import TaskStatusEnum from "common/enums/TaskStatusEnum";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";
import FeatureFlags from "common/config/FeatureFlags";
import { PersonIcon } from "../assets/images/icons";

const USER_COUNT_INSERT = "{{COUNT}}";

export const MEMBERS_PATH = "/members/memberId/:memberId";
export const MEMBERS_OVERVIEW_PATH = "/members/memberId/:memberId/overview";
export const CAREFLOW_PATH = "/visits/:visitId";

const MEMBER_SORT = "lastName,ASC,firstName,ASC";

const USER_FULLNAME_ACCESSOR = "user.fullname";
const MEMBER_FULLNAME_ACCESSOR = "patient.fullname";
const MEMBER_TIMEZONE = "patient.timezone";
const JOINED_ACCESSOR = "user.joined";
const MEMBER_INACTIVITY_INACTIVATED_DATE =
  "patient_inactivity.inactivated_date";

const SEARCH_BY_NAME_PLACEHOLDER = "Search by name";

// This is temporal and should be done on the backend.
const MEMBER_RETENTION_SORTING = (a: MemberType, b: MemberType) => {
  if (
    a.patient_retention_actions === undefined ||
    a.patient_retention_actions.length === 0
  )
    return -1;
  if (
    b.patient_retention_actions === undefined ||
    b.patient_retention_actions.length === 0
  )
    return 1;

  const lastRetentionA =
    a.patient_retention_actions[a.patient_retention_actions.length - 1];
  const lastRetentionB =
    b.patient_retention_actions[b.patient_retention_actions.length - 1];

  const dateTimeA = DateTime.fromSQL(lastRetentionA.created_at);
  const dateTimeB = DateTime.fromSQL(lastRetentionB.created_at);

  if (dateTimeA < dateTimeB) return -1;
  else return 1;
};

const getTableHeaderText = (splitByMemberStatus: boolean) => {
  if (splitByMemberStatus) {
    return `${USER_COUNT_INSERT} members ({{MemberStatusEnum.ACTIVE}} active and {{MemberStatusEnum.PENDING}} pending)`;
  } else {
    return `${USER_COUNT_INSERT} members`;
  }
};

interface TableProps {
  size?: number;
  externalLink?: string;
  componentHeader?: string;
  roles?: RolesEnum[];
  showsTableHeader?: boolean;
  tableColumns?: TableColumn[];
  recentOrdersSinceDays?: number;
  promiseFunctionNames?: string[];
  splitByMemberStatus?: boolean;
  filterByActiveStatus?: boolean;
  searchEnabled?: boolean;
}

export const MEMBER_DATA = ({ hideReadings = true }) => {
  return {
    path: "/members/memberId/:memberId",
    components: [
      {
        // v1 url points to v2 overview
        type: RouteComponentTypeEnum.NEW_MEMBER_DETAILS_OVERVIEW,
        componentParams: {
          hideReadings
        }
      }
    ]
  };
};

export const MEMBER_CHART = (role: RolesEnum) => {
  const hideEncounters = !canSeeEncounters(role);
  const hideOrders = !canSeeMemberOrders(role);
  const hideReadings = !canSeeReadings(role);

  return [
    {
      path: "/members/memberId/:memberId/overview",
      components: [
        {
          type: RouteComponentTypeEnum.NEW_MEMBER_DETAILS_OVERVIEW,
          componentParams: {
            hideReadings
          }
        }
      ]
    },
    !hideEncounters && {
      path: "/members/memberId/:memberId/encounters",
      components: [
        {
          type: RouteComponentTypeEnum.NEW_MEMBER_DETAILS_ENCOUNTERS,
          componentParams: {
            hideReadings
          }
        }
      ]
    },
    !hideOrders && {
      path: "/members/memberId/:memberId/orders",
      components: [
        {
          type: RouteComponentTypeEnum.NEW_MEMBER_DETAILS_ORDERS,
          componentParams: {
            hideReadings
          }
        }
      ]
    },
    {
      path: "/members/memberId/:memberId/profile",
      components: [
        {
          type: RouteComponentTypeEnum.NEW_MEMBER_DETAILS_PROFILE,
          componentParams: {
            hideReadings
          }
        }
      ]
    },
    {
      path: "/members/memberId/:memberId/profile/:tab",
      components: [
        {
          type: RouteComponentTypeEnum.NEW_MEMBER_DETAILS_PROFILE,
          componentParams: {
            hideReadings
          }
        }
      ]
    },
    {
      // redirect for athena router
      path: "/athenaredirect/:memberId",
      components: [
        {
          type: RouteComponentTypeEnum.NEW_MEMBER_DETAILS_OVERVIEW,
          componentParams: {
            hideReadings,
            athenaRedirect: true
          }
        }
      ]
    },
    {
      // redirect for patientId
      path: "/members/memberId/:memberId",
      components: [
        {
          type: RouteComponentTypeEnum.PATIENT_DETAILS_REDIRECT,
          componentParams: {
            hideReadings
          }
        }
      ]
    },
    {
      // redirect for patient cancellations
      path: "/patients/patientId/:memberId/cancellations-history",
      components: [
        {
          type: RouteComponentTypeEnum.PATIENT_CANCELLATION_REDIRECT,
          componentParams: {
            survey_type: "PATIENT_ATTRITION",
            linked_entities: [SurveyLinkedEntitiesEnum.SUBMITTER],
            componentHeader: "Cancellation Request History",
            tableColumns: [
              { name: "date", accessor: "patient_survey.created_at" },
              { name: "name", accessor: "submitter" },
              {
                name: "roles",
                accessor: "submitter.roles",
                header: "Role"
              },
              {
                name: "default",
                id: "reasonCategory",
                accessor: "category",
                header: "Category"
              },
              {
                name: "default",
                id: "subCategory",
                accessor: "subCategory",
                header: "Sub Category"
              },
              {
                name: "notes",
                id: "notes",
                accessor: "notes",
                header: "Notes"
              }
            ]
          }
        }
      ]
    },
    MEMBER_DATA({ hideReadings }),
    !hideReadings && {
      path: "/members/memberId/:memberId/readings/:readingType",
      components: [
        {
          type: RouteComponentTypeEnum.NEW_MEMBER_DETAILS_READINGS,
          componentParams: {
            hideReadings
          }
        }
      ]
    },
    {
      path: "/members/memberId/:memberId/appointments",
      components: [
        {
          type: RouteComponentTypeEnum.NEW_MEMBER_DETAILS_APPOINTMENTS,
          componentParams: {
            hideReadings
          }
        }
      ]
    },
    !hideEncounters && {
      path: "/members/memberId/:memberId/goals",
      components: [
        {
          type: RouteComponentTypeEnum.NEW_MEMBER_DETAILS_GOALS,
        }
      ]
    },
    {
      path: "members/memberId/:memberId/cancellations-history",
      components: [
        {
          type: RouteComponentTypeEnum.REPORTED_CANCELLATIONS_BY_MEMBER,
          componentParams: {
            survey_type: "PATIENT_ATTRITION",
            linked_entities: [SurveyLinkedEntitiesEnum.SUBMITTER],
            componentHeader: "Cancellation Request History",
            tableColumns: [
              { name: "date", accessor: "patient_survey.created_at" },
              { name: "name", accessor: "submitter" },
              {
                name: "roles",
                accessor: "submitter.roles",
                header: "Role"
              },
              {
                name: "default",
                id: "reasonCategory",
                accessor: "category",
                header: "Category"
              },
              {
                name: "default",
                id: "subCategory",
                accessor: "subCategory",
                header: "Sub Category"
              },
              {
                name: "notes",
                id: "notes",
                accessor: "notes",
                header: "Notes"
              }
            ]
          }
        }
      ]
    }
  ];
};

export const MEMBER_CHART_CALENDARING = () => {
  return [
    {
      path: "/members/memberId/:memberId/new-appointment",
      components: [
        {
          type: RouteComponentTypeEnum.NEW_MEMBER_APPOINTMENT,
          componentParams: {}
        }
      ]
    },
    {
      path: "/members/memberId/:memberId/edit-appointment",
      components: [
        {
          type: RouteComponentTypeEnum.EDIT_MEMBER_APPOINTMENT,
          componentParams: {}
        }
      ]
    }
  ];
};

export const RECENT_ORDERS = () => {
  return {
    type: "recent-orders",
    componentParams: {
      componentName: "RECENT_ORDERS",
      tableColumns: [
        { name: "orderPatientName" },
        { name: "orderPatientStatus" },
        { name: "ordered", size: 100 },
        {
          name: "orderStatus"
        },
        { name: "orderShipped", size: 100 },
        { name: "orderDelivered", size: 120 },
        { name: "tracking" },
        { name: "orderItems", type: "dataList" }
      ],
      tableHeader: `${USER_COUNT_INSERT} orders`,
      externalLink: MEMBERS_PATH
    }
  };
};

export const ALL_RECENT_ORDERS = () => {
  return {
    type: "all-recent-orders",
    componentParams: {
      tableColumns: [
        { name: "order_id", size: 250 },
        { name: "orderPatientName" },
        { name: "orderPatientStatus" },
        { name: "ordered", size: 100 },
        {
          name: "orderType",
          filterEnabled: true,
          filterFn: "orderType"
        },
        {
          name: "orderStatus",
          filterEnabled: true,
          filterFn: "orderStatus"
        },
        { name: "orderShipped", size: 100 },
        { name: "orderDelivered", size: 120 },
        { name: "tracking" },
        { name: "ordered_by" },
        { name: "orderItems", type: "dataList" }
      ],
      tableHeader: `${USER_COUNT_INSERT} orders`,
      externalLink: MEMBERS_PATH
    }
  };
};

export const ORDER_DETAILS = () => {
  return {
    path: "/orders/:orderId",
    components: [
      {
        type: "order-devices-details"
      }
    ]
  };
};

export const ALL_MEMBERS = ({
  splitByMemberStatus = true,
  size = 250,
  tableColumns = [
    { name: "name" },
    { name: "status" },
    { name: "phone" },
    { name: "birthdate" },
    { name: "nurseAssigned" }
  ],
  externalLink = MEMBERS_PATH
}: TableProps) => {
  const tableHeader = getTableHeaderText(splitByMemberStatus);
  return {
    type: RouteComponentTypeEnum.CUSTOM_SEARCH_TABLE,
    componentParams: {
      userListFilters: {
        status: [
          MemberStatusEnum.PENDING,
          MemberStatusEnum.ACTIVE,
          MemberStatusEnum.INACTIVE,
          MemberStatusEnum.NEVER_ENGAGED
        ],
        size,
        linked_entities: [MemberLinkedEntitiesEnum.NURSE]
      },
      tableHeader: tableHeader,
      externalLink,
      tableColumns,
      componentHeader: "Find Members"
    }
  };
};

export const UNASSIGNED_MEMBERS = {
  type: RouteComponentTypeEnum.PATIENT_LIST,
  componentParams: {
    userListFilters: {
      status: [
        MemberStatusEnum.ACTIVE,
        MemberStatusEnum.PENDING,
        MemberStatusEnum.ELIGIBLE,
        MemberStatusEnum.INACTIVE,
        MemberStatusEnum.NEVER_ENGAGED,
        MemberStatusEnum.REEVALUATING_PATIENT
      ],
      has_nurse_assigned: "false",
      sort: MEMBER_SORT
    },
    tableColumns: [
      {
        name: "fullname",
        accessor: "fullname",
        filterEnabled: true,
        filterFn: "fuzzy",
        size: 180
      },
      { name: "status" },
      { name: "phone" },
      { name: "birthdate" },
      { name: "patientActions", size: 75 }
    ],
    externalLink: MEMBERS_PATH,
    tableHeader: getTableHeaderText(true),
    componentHeader: "Unassigned Members",
    promiseFunctionNames: []
  }
};

export const AWAITING_PROVIDER = ({
  size = 250,
  tableColumns = [{ name: "name" }, { name: "phone" }, { name: "birthdate" }]
}: TableProps) => {
  return {
    type: RouteComponentTypeEnum.CUSTOM_SEARCH_TABLE,
    componentParams: {
      userListFilters: {
        status: [MemberStatusEnum.QUALIFIED, MemberStatusEnum.ELIGIBLE],
        linked_entities: [MemberLinkedEntitiesEnum.NURSE],
        size
      },
      externalLink: MEMBERS_PATH,
      tableColumns,
      componentHeader: "Awaiting Provider"
    }
  };
};

export const INACTIVE_MEMBERS = (hasActions: boolean) => {
  const tableHeader = getTableHeaderText(false);
  const tableColumns: TableColumn[] = [
    { name: "name" },
    { name: "status" },
    { name: "phone" },
    { name: "birthdate" },
    { name: "nurseAssigned" },
    { name: "patientActivityLastNurseEncounter" },
    { name: "patientActivityLastReading" },
    { name: "patientSince", size: 140 }
  ];
  if (hasActions) tableColumns.push({ name: "patientActions", size: 75 });

  return {
    type: RouteComponentTypeEnum.PATIENT_ACTIVITY_LIST,
    componentParams: {
      userListFilters: {
        status: [MemberStatusEnum.INACTIVE],
        linked_entities: [MemberLinkedEntitiesEnum.NURSE]
      },
      tableHeader,
      tableColumns,
      externalLink: MEMBERS_PATH,
      componentHeader: "All Inactive Members"
    }
  };
};

export const REGISTER_MEMBER = {
  type: RouteComponentTypeEnum.REGISTER_MEMBER,
  componentParams: {
    containerMaxWidth: "700px"
  }
};

export const REQUEST_CONSENTS = {
  type: RouteComponentTypeEnum.REQUEST_CONSENTS
};

export const MY_CONVERSIONS = {
  type: RouteComponentTypeEnum.PATIENT_LIST,
  componentParams: {
    userListFilters: {
      status: [MemberStatusEnum.PENDING, MemberStatusEnum.ACTIVE],
      linked_entities: [MemberLinkedEntitiesEnum.NURSE]
    },
    tableColumns: [
      {
        name: "fullname",
        accessor: "fullname",
        filterEnabled: true,
        filterFn: "fuzzy"
      },
      { name: "status" },
      { name: "phone" },
      { name: "birthdate" },
      { name: "patientSince", size: 140 },
      { name: "nurseAssigned" }
    ],
    dateFilterField: DateFilterFieldEnum.ENROLLED,
    componentHeader: "My Conversions",
    promiseFunctionNames: [],
    tableHeader: `${USER_COUNT_INSERT} member conversions ({{MemberStatusEnum.ACTIVE}} active and {{MemberStatusEnum.PENDING}} pending)`
  }
};

export const MY_REGISTRATIONS = {
  type: RouteComponentTypeEnum.PATIENT_LIST,
  componentParams: {
    userListFilters: {
      status: [MemberStatusEnum.QUALIFIED]
    },
    tableColumns: [
      {
        name: "fullname",
        accessor: "fullname",
        filterEnabled: true,
        filterFn: "fuzzy"
      },
      { name: "phone" },
      { name: "birthdate" },
      { name: "joined", size: 105 }
    ],
    dateFilterField: DateFilterFieldEnum.CREATED_AT,
    componentHeader: "My Registrations",
    promiseFunctionNames: [],
    tableHeader: `${USER_COUNT_INSERT} lead registrations`
  }
};

export const NURSE_LIST = ({
  externalLink = "/staff/staffId/:userId/assignments",
  tableColumns = [
    {
      name: "fullname",
      accessor: USER_FULLNAME_ACCESSOR,
      filterEnabled: true,
      filterFn: "fuzzy",
      size: 300
    },
    { name: "teamName", accessor: "team.name", externalLink: "/team/:userId" },
    {
      name: "roles",
      size: 200,
      type: "dataList"
    },
    { name: "joined", accessor: JOINED_ACCESSOR, size: 105 }
  ],
  componentHeader = "Nurses List",
  roles = [RolesEnum.TH_NURSE, RolesEnum.THN_MANAGER]
}: TableProps) => {
  const tableHeader = `${USER_COUNT_INSERT} users`;
  return {
    type: RouteComponentTypeEnum.STAFF_LIST,
    componentParams: {
      userListFilters: {
        roles,
        sort: MEMBER_SORT,
        status: [UserStatusEnum.ACTIVE],
        linked_entities: [UserLinkedEntitiesEnum.TEAM]
      },
      tableColumns,
      tableHeader,
      searchPlaceholder: SEARCH_BY_NAME_PLACEHOLDER,
      externalLink,
      componentHeader
    }
  };
};

export const PROVIDER_LIST = ({
  componentHeader = "All Providers",
  tableColumns = [
    {
      name: "fullname",
      accessor: USER_FULLNAME_ACCESSOR,
      filterEnabled: true,
      filterFn: "fuzzy",
      size: 300
    },
    {
      name: "roles",
      size: 200,
      type: "dataList"
    },
    { name: "joined", accessor: JOINED_ACCESSOR, size: 105 }
  ],
  roles = [
    RolesEnum.MD_PROVIDER,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.PROVIDER_MANAGER
  ],
  filterByAuthenticatedUser = false
}) => {
  const tableHeader = `${USER_COUNT_INSERT} providers`;

  return {
    type: RouteComponentTypeEnum.STAFF_LIST,
    componentParams: {
      userListFilters: {
        roles,
        sort: MEMBER_SORT,
        status: [UserStatusEnum.ACTIVE],
        linked_entities: filterByAuthenticatedUser
          ? [UserLinkedEntitiesEnum.TEAM]
          : []
      },
      externalLink: "/staff/staffId/:staffId/assignments",
      tableColumns,
      tableHeader,
      searchPlaceholder: SEARCH_BY_NAME_PLACEHOLDER,
      componentHeader,
      filterByAuthenticatedUser
    }
  };
};

export const PROVIDER_SCHEDULES = ({
  componentHeader = "Providers Schedules",
  tableColumns = [
    {
      name: "fullname",
      accessor: USER_FULLNAME_ACCESSOR,
      filterEnabled: true,
      filterFn: "fuzzy",
      size: 300
    },
    {
      name: "roles",
      size: 200,
      type: "dataList"
    },
    { name: "joined", accessor: JOINED_ACCESSOR, size: 105 }
  ],
  roles = [RolesEnum.MD_PROVIDER, RolesEnum.NURSE_PROVIDER],
  filterByAuthenticatedUser = false
}) => {
  const tableHeader = `${USER_COUNT_INSERT} providers`;

  return {
    type: RouteComponentTypeEnum.STAFF_LIST,
    componentParams: {
      userListFilters: {
        roles,
        sort: MEMBER_SORT,
        status: [UserStatusEnum.ACTIVE],
        linked_entities: filterByAuthenticatedUser
          ? [UserLinkedEntitiesEnum.TEAM]
          : []
      },
      externalLink: "/staff/staffId/:staffId/schedule",
      tableColumns,
      tableHeader,
      searchPlaceholder: SEARCH_BY_NAME_PLACEHOLDER,
      componentHeader,
      filterByAuthenticatedUser
    }
  };
};

export const PROVIDER_SCHEDULES_SIDEBAR = {
  itemName: "Provider Schedules",
  itemLink: "/provider-schedules",
  ItemIcon: PersonIcon
};

export const NPS_LIST = {
  type: RouteComponentTypeEnum.STAFF_LIST,
  componentParams: {
    containerMaxWidth: "700px",
    userListFilters: {
      roles: [RolesEnum.NPS]
    },
    tableColumns: [
      { name: "fullname" },
      { name: "roles", size: 200, type: "dataList" },
      { name: "joined", accessor: JOINED_ACCESSOR, size: 105 }
    ],
    searchPlaceholder: SEARCH_BY_NAME_PLACEHOLDER,
    componentHeader: "NMS List"
  }
};

// ONLY ADMIN & TECH SUPPORT should be able to reset user password
// https://copilotiq.atlassian.net/browse/ENG-2807
export const STAFF_MEMBERS = {
  type: RouteComponentTypeEnum.STAFF_LIST,
  componentParams: {
    componentHeader: "Staff Members",
    userListFilters: {
      roles: [...StaffRoles],
      status: [MemberStatusEnum.ACTIVE],
      sort: MEMBER_SORT
    },
    tableHeader: `${USER_COUNT_INSERT} users`,
    externalLink: "/staff/staffId/:staffId/assignments",
    tableColumns: [
      {
        name: "fullname",
        filterEnabled: true,
        filterFn: "fuzzy",
        size: 300
      },
      {
        name: "roles",
        size: 200,
        type: "dataList",
        filterEnabled: true,
        filterFn: "roles"
      },
      { name: "joined", accessor: JOINED_ACCESSOR, size: 105 },
      { name: "staffActions", size: 75 }
    ]
  }
};

export const MEMBERS_WITH_NO_ACTIVITY = ({
  tableColumns = [
    {
      name: "name",
      accessor: MEMBER_FULLNAME_ACCESSOR,
      filterEnabled: false,
      filterFn: "fuzzy",
      size: 180
    },
    { name: "carerPatientTableStatus", size: 200 },
    { name: "phone" },
    { name: "carerPatientTableBirthdate", size: 130 },
    { name: "carerPatientTableReadings", size: 130 },
    { name: "nurseReportDataNextNurseAppointment", size: 220 },
    // TBD - comment this in when we enable scheduling appointments for providers calendaring
    // { name: "nurseReportDataNextProviderAppointment" },
    { name: "nurseReportDataLastEncounter", size: 140 },
    // remove for now, per Yuly's feedback - comment back in if needed
    // { name: "connectedMTD", size: 150 },
    { name: "nonDeviceSetupMTD", size: 160 },
    { name: "carerPatientTableDelivered", size: 120 },
    { name: "carerPatientSince", size: 140 },
    { name: "carerPatientTableProviderLastEncounter", size: 220 }
  ],
  recentOrdersSinceDays = 30,
  promiseFunctionNames = [
    "getEncountersData",
    "getPeriodReadings",
    "getOrdersUsingPatientId",
    "getReadingsByPatientId"
  ],
  splitByMemberStatus = false
}: TableProps) => {
  const tableHeader = getTableHeaderText(splitByMemberStatus);
  return {
    type: RouteComponentTypeEnum.CARER_PATIENT_LIST,
    componentParams: {
      componentName: "MEMBERS_WITH_NO_ACTIVITY",
      tableHeader,
      tableHeaderUserAccessor: "patient.status",
      marginBottom: "22px",
      componentHeader: "Members With No Activity",
      externalLink: MEMBERS_PATH,
      searchEnabled: true,
      ComponentTooltip: (
        <div>
          Members that haven’t taken readings in the last 7 days
          <br />
          or that haven’t had a nurse encounter in the last 14 days
        </div>
      ),
      isNoActivityList: true,
      tableColumns,
      recentOrdersSinceDays,
      promiseFunctionNames
    }
  };
};

export const ASSIGNED_MEMBERS = ({
  tableColumns = [
    {
      name: "name",
      accessor: MEMBER_FULLNAME_ACCESSOR,
      filterEnabled: false,
      filterFn: "fuzzy",
      size: 180
    },
    { name: "status" },
    { name: "phone" },
    { name: "birthdate", size: 130 },
    { name: "carerPatientTableReadings", size: 130 },
    { name: "nurseReportDataNextNurseAppointment", size: 220 },
    // TBD - comment this in when we enable scheduling appointments for providers calendaring
    // { name: "nurseReportDataNextProviderAppointment" },
    { name: "nurseReportDataLastEncounter" },
    // remove for now, per Yuly's feedback - comment back in if needed
    // { name: "connectedMTD", size: 150 },
    { name: "nonDeviceSetupMTD", size: 160 },
    { name: "carerPatientTableDelivered", size: 120 },
    { name: "carerPatientSince", size: 140 },
    { name: "carerPatientTableProviderLastEncounter", size: 220 }
  ],
  promiseFunctionNames = [
    "getEncountersData",
    "getPeriodReadings",
    "getOrdersUsingPatientId",
    "getReadingsByPatientId"
  ],
  recentOrdersSinceDays = 30,
  componentHeader = "Assigned Members",
  showsTableHeader = true,
  splitByMemberStatus = false
}: TableProps) => {
  const tableHeader = getTableHeaderText(splitByMemberStatus);
  return {
    type: RouteComponentTypeEnum.CARER_PATIENT_LIST,
    componentParams: {
      componentName: "ASSIGNED_MEMBERS",
      tableHeader: showsTableHeader ? tableHeader : "",
      marginBottom: "22px",
      tableHeaderUserAccessor: "patient.status",
      componentHeader,
      searchEnabled: true,
      promiseFunctionNames,
      recentOrdersSinceDays,
      isNoActivityList: false,
      tableColumns,
      externalLink: MEMBERS_PATH
    }
  };
};

export const NPN_LIST = ({
  tableColumns = [
    {
      name: "fullname",
      accessor: USER_FULLNAME_ACCESSOR,
      filterEnabled: true,
      filterFn: "fuzzy",
      size: 300
    },
    {
      name: "roles",
      size: 200,
      type: "dataList"
    },
    { name: "joined", accessor: JOINED_ACCESSOR, size: 105 }
  ],
  componentHeader = "Intake Nurses"
}: TableProps) => {
  const tableHeader = `${USER_COUNT_INSERT} users`;

  return {
    path: "/nmns",
    components: [
      {
        type: RouteComponentTypeEnum.STAFF_LIST,
        componentParams: {
          userListFilters: {
            roles: [RolesEnum.NP_NURSE],
            sort: MEMBER_SORT
          },
          tableColumns,
          tableHeader,
          searchPlaceholder: SEARCH_BY_NAME_PLACEHOLDER,
          externalLink: "/npn/:userId",
          componentHeader
        }
      }
    ]
  };
};

export const NPN_DETAILS = {
  path: "/npn/:userId",
  components: [
    {
      type: "intakes",
      componentParams: {
        componentHeader: "Intakes",
        externalLink: MEMBERS_PATH,
        linked_entities: [MemberLinkedEntitiesEnum.NURSE]
      }
    }
  ]
};

export const REPORT_LIST = {
  path: "/reports",
  components: [
    {
      type: "report-list"
    }
  ]
};

export const REPORT_DETAILS = {
  path: "/reports/:reportId",
  components: [
    {
      type: "reports"
    }
  ]
};

export const CANCELED_MEMBERS = {
  path: "/cancellations-history",
  components: [
    {
      type: RouteComponentTypeEnum.REPORTED_CANCELLATIONS,
      componentParams: {
        submitter_roles: [RolesEnum.RETENTION_SPECIALIST, RolesEnum.ADMIN],
        componentHeader: "Cancellations History",
        tableHeader: `${USER_COUNT_INSERT} members`,
        size: 350
      }
    }
  ]
};

export const REPORT_DETAILS_BY_MEMBER = {
  path: "/reports/:reportId/memberId/:memberId",
  components: [
    {
      type: "reports"
    }
  ]
};

// export const ALL_TEAMS = {
//   path: "/all-teams",
//   components: [
//     {
//       type: RouteComponentTypeEnum.ALL_TEAMS
//     }
//   ]
// };

export const ALL_TEAMS = ({
  types = undefined
}: {
  types?: TeamTypeEnum[];
}) => {
  return {
    path: "/all-teams",
    components: [
      {
        type: RouteComponentTypeEnum.ALL_TEAMS,
        componentParams: {
          types
        }
      }
    ]
  };
};

export const TEAM_DETAILS = {
  path: "/team/:userId",
  components: [
    {
      type: "selected-team",
      componentParams: {
        externalLink: "/staff/staffId/:userId/assignments"
      }
    }
  ]
};

export const RETENTION = ({
  splitByMemberStatus = false,
  filterByAssignedNurse = false
}) => {
  const tableHeader = getTableHeaderText(splitByMemberStatus);

  return {
    path: "/retention/:tabId",
    components: [
      {
        type: RouteComponentTypeEnum.INACTIVE_PATIENT_LIST,
        componentParams: {
          tableHeader,
          filterByAssignedNurse,
          userListFilters: {
            status: [MemberStatusEnum.INACTIVE],
            size: 100000,
            linked_entities: [
              MemberLinkedEntitiesEnum.PATIENT_INACTIVITY,
              MemberLinkedEntitiesEnum.RETENTION_ACTIONS,
              MemberLinkedEntitiesEnum.NURSE,
              MemberLinkedEntitiesEnum.LATEST_ATTRITION_REASONS
            ]
          },
          sortBy: (a, b) => MEMBER_RETENTION_SORTING(a, b),
          externalLink: MEMBERS_PATH,
          tableColumns: [
            { name: "contactAttempts" },
            { name: "fullname", accessor: MEMBER_FULLNAME_ACCESSOR },
            {
              name: "phone"
            },
            {
              name: "timezone",
              id: "timezone",
              accessor: MEMBER_TIMEZONE,
              header: "Timezone"
            },
            {
              name: "date",
              id: "inactive_date",
              accessor: MEMBER_INACTIVITY_INACTIVATED_DATE,
              header: "Date Inactive",
              sortingFn: "dateTimeSortingISO"
            },
            { name: "lastContact" },
            { name: "latestAttritionReasonCategory" },
            { name: "latestAttritionReasonSubCategory" },
            { name: "latestAttritionReasonNotes" },
            { name: "lastContactAction", size: 100 }
          ],
          ComponentTooltip: (
            <div>
              Members that haven’t taken any readings or had
              <br />
              any encounters for 30 to 60 days
            </div>
          ),
          componentHeader: `${
            filterByAssignedNurse ? "My " : ""
          }Inactive Members`
        }
      }
    ]
  };
};

export const CREATE_TEAM = {
  path: "/create-team",
  components: [
    {
      type: RouteComponentTypeEnum.CREATE_TEAM
    }
  ]
};

export const MY_TODOS = {
  path: "/tasks",
  components: [
    {
      type: RouteComponentTypeEnum.TASKS,
      componentParams: {
        tasksStatuses: [TaskStatusEnum.TODO, TaskStatusEnum.IN_PROGRESS],
        componentHeader: "To Dos",
        showWhenNoTasks: true,
        noTasksMessage:
          "You have no pending tasks that require imminent action.",
        tableHeight: "90%",
        showResolveButton: true,
        showActionButtons: false,
        link: {
          text: "View Completed",
          route: "/my-completed-todos"
        }
      }
    }
  ]
};

export const COMPLETED_TODOS = {
  path: "/my-completed-todos",
  components: [
    {
      type: RouteComponentTypeEnum.TASKS,
      componentParams: {
        tasksStatuses: [TaskStatusEnum.COMPLETED],
        componentHeader: "Completed Tasks",
        noTasksMessage:
          "You haven't completed any tasks yet. This is where you’ll see your completed tasks",
        showResolveButton: false,
        showActionButtons: false,
        tableHeight: "90%"
      }
    }
  ]
};

export const STAFF_DETAILS = {
  path: "/staff/staffId/:staffId/:tab",
  components: [
    {
      type: RouteComponentTypeEnum.STAFF_DETAILS
    }
  ]
};

export const SCHEDULE_TODO = (isDashboard = false) => {
  return {
    type: RouteComponentTypeEnum.NEW_SCHEDULE_TODO_CONTAINER,
    componentParams: {
      rightComponent: RouteComponentTypeEnum.TASKS,
      isDashboard: isDashboard
    }
  };
};

export const NURSE_SCHEDULE = {
  path: "/nurses/nurse-schedule/nurseId/:nurseId",
  components: [
    {
      type: "nurse-details",
      componentParams: {
        header: "Nurse Schedule"
      }
    },
    SCHEDULE_TODO,
    ASSIGNED_MEMBERS({
      recentOrdersSinceDays: 15,
      promiseFunctionNames: [
        "getEncountersData",
        "getPeriodReadings",
        "getOrdersUsingPatientId",
        "getReadingsByPatientId"
      ]
    })
  ]
};

export const NURSE_SCHEDULES = {
  path: "/nurse-schedules",
  components: [
    NURSE_LIST({
      componentHeader: "Nurse Schedules",
      roles: [RolesEnum.TH_NURSE],
      externalLink: "/staff/staffId/:nurseId/schedule",
      tableColumns: [
        {
          name: "fullname",
          accessor: USER_FULLNAME_ACCESSOR,
          filterEnabled: true,
          filterFn: "fuzzy",
          size: 300
        }
      ]
    })
  ]
};

export const NURSES_SCHEDULES = [
  // let's keep the old path for a bit to deprecate it before removing it
  {
    path: "/nurses-schedules/same-day-time-off/:nurseId",
    components: [
      {
        type: "same-day-time-off"
      }
    ]
  },
  {
    path: "/nurses-schedules/time-off/:nurseId",
    components: [
      {
        type: "time-off"
      }
    ]
  },
  {
    path: "/nurses-schedules/manual-reassignment/:eventId",
    components: [
      {
        type: "new-manual-reassign-appointment"
      }
    ]
  },
  {
    path: "/nurses-schedules/:tab",
    components: [
      {
        type: "new-nurses-schedules"
      }
    ]
  }
];

export const VISITS = {
  path: "/visits/:visitId",
  components: [{ type: RouteComponentTypeEnum.VISITS }]
};

export const ARC_OF_DAY_DASHBOARD = FeatureFlags().ARC_OF_DAY && {
  path: "/aod-dashboard",
  components: [{ type: RouteComponentTypeEnum.ARC_OF_DAY_DASHBOARD }]
};
