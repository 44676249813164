import RolesEnum from "common/enums/RolesEnum";
import {
  ALL_MEMBERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  ORDER_DETAILS,
  REPORT_DETAILS,
  REPORT_LIST,
  REPORT_DETAILS_BY_MEMBER,
  MEMBER_CHART,
  MEMBER_CHART_CALENDARING,
  STAFF_DETAILS,
  SCHEDULE_TODO,
  PROVIDER_SCHEDULES_SIDEBAR,
  PROVIDER_SCHEDULES,
  PROVIDER_LIST
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_MY_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SUPPORT,
  SIDEBAR_SECTIONS
} from "../RouteSidebar";
import RouteComponentTypeEnum from "common/enums/RouteComponentTypeEnum";
import { PersonIcon } from "../../assets/images/icons";
import EnvVars from "common/config/EnvVars";
import FeatureFlags from "common/config/FeatureFlags";

const sidebar = () => {
  const sidebarItems = [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [
        SIDEBAR_MY_MEMBERS,
        SIDEBAR_ALL_MEMBERS,
        SIDEBAR_INACTIVE_MEMBERS,
        SIDEBAR_ORDERS
      ]
    }
  ];
  // provider_calendaring_feature_flag
  if (FeatureFlags().PROVIDER_CALENDARING) {
    sidebarItems.push({
      sectionName: SIDEBAR_SECTIONS.providers,
      items: [
        {
          itemName: "All Providers",
          itemLink: "/providers",
          ItemIcon: PersonIcon
        },
        PROVIDER_SCHEDULES_SIDEBAR
      ]
    });
  }

  sidebarItems.push(SIDEBAR_REPORTS);
  sidebarItems.push(SIDEBAR_SUPPORT);
  return sidebarItems;
};

const MD_PROVIDER = {
  label: "Medical Provider",
  routes: [
    {
      path: "/",
      components: [SCHEDULE_TODO(true), ALL_MEMBERS({})]
    },
    {
      path: "/nurses/nurseId/:userId",
      components: [{ type: RouteComponentTypeEnum.MEMBER_DETAILS_REDIRECT }]
    },
    STAFF_DETAILS,
    {
      path: "/members",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/awaiting-provider",
      components: [
        AWAITING_PROVIDER({
          tableColumns: [
            { name: "name" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" }
          ]
        })
      ]
    },
    { path: "/inactive-members", components: [INACTIVE_MEMBERS(true)] },
    ...MEMBER_CHART(RolesEnum.MD_PROVIDER),
    ...MEMBER_CHART_CALENDARING(),
    {
      path: "/members/nurseId/:nurseId",
      components: [
        ASSIGNED_MEMBERS({
          promiseFunctionNames: ["getOrdersUsingPatientId"],
          tableColumns: [
            {
              name: "name",
              accessor: "patient.fullname",
              filterEnabled: false,
              filterFn: "fuzzy",
              size: 180
            },
            { name: "status" },
            { name: "phone" },
            { name: "birthdate", size: 130 }
          ],
          componentHeader: "My Members"
        })
      ]
    },
    {
      path: "/orders",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    {
      path: "/devices/:memberId",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    ORDER_DETAILS(),
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    {
      path: "/help",
      components: [
        {
          type: "help"
        }
      ]
    },
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    // provider_calendaring_feature_flag
    FeatureFlags().PROVIDER_CALENDARING && {
      path: "/provider-schedules",
      components: [PROVIDER_SCHEDULES({})]
    },
    FeatureFlags().PROVIDER_CALENDARING && {
      path: "/providers",
      components: [
        PROVIDER_LIST({
          filterByAuthenticatedUser: true
        })
      ]
    }
  ],
  sidebar: sidebar()
};

export default MD_PROVIDER;
