import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";
import { useGetReadingsTrendsByMemberQuery } from "common/services/ReadingsService";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import MemberType from "common/types/MemberType";
import { useEffect } from "react";
import { isFalsy } from "common/helpers/helpers";
import { useAppDispatch } from "common/redux";
import { setAnswer } from "common/redux/VisitsSlice";
import GlucoseReadingTagsEnum from "common/enums/GlucoseReadingTagsEnum";

interface IProps {
  field: CareFlowFieldType;
  days: number;
  member: MemberType;
}

const VisitFieldTrendBloodPressure = ({ field, days, member }: IProps) => {
  const dispatch = useAppDispatch();

  const { data } = useGetReadingsTrendsByMemberQuery({
    memberId: member?.patient?.patient_id,
    paramsObject: {
      device_type: [DeviceTrendParam.BLOOD_PRESSURE],
      trend_days: [days]
    }
  });

  useEffect(() => {
    if (isFalsy(data)) return;
    const trends = data.blood_pressure?.trends;
    if (isFalsy(trends) || isFalsy(trends[days.toString()])) return;

    const trendData = trends[days.toString()];
    dispatch(
      setAnswer({
        id: field.field_id,
        value: `Blood Pressure ${days.toString()}-day average: ${trendData?.average_systolic} / ${trendData?.average_diastolic}`
      })
    );
  }, [data]);
  return null;
};

export default VisitFieldTrendBloodPressure;
