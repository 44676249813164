import { useSelector } from "react-redux";

import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { setAnswer } from "common/redux/VisitsSlice";
import { RootState, useAppDispatch } from "common/redux";

import { DateTime } from "luxon";
import { useMemo } from "react";
import DateTimePicker from "../../../components/DateTimePicker";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";

interface IProps {
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
}

const VisitFieldDateTime = ({ field, visit }: IProps) => {
  const dispatch = useAppDispatch();
  const { answers } = useSelector((state: RootState) => state.visits);

  const onKeyDown = (e) => {
    if (e.key === "Delete") {
      // TBD ENG-5032 fix this case when pressing fn + delete, these all don't work :(
      e.target = null;
      e.preventDefault();
      e.nativeEvent.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    e.preventDefault();
  };

  const startDate = useMemo(() => {
    if (answers[field.field_id] === undefined) return undefined;
    return DateTime.fromISO(answers[field.field_id] as string);
  }, []);

  const disabled = visit.status !== VisitStatusEnum.IN_PROGRESS;

  return (
    <DateTimePicker
      sx={{ marginTop: 0 }}
      label={field.label}
      value={startDate}
      disabled={disabled}
      onChange={(newDate: DateTime) => {
        dispatch(
          setAnswer({
            id: field.field_id,
            value: newDate.toISO()
          })
        );
      }}
      slotProps={{
        field: {
          readOnly: true
        },
        textField: { onKeyDown }
      }}
    />
  );
};

export default VisitFieldDateTime;
