import { Box, Typography } from "@mui/material";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { DateTime } from "luxon";
import ErrorComponent from "../../../components/ErrorComponent";
import Table from "../../../components/Table/Table";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { getDispositionIndexInVisits } from "common/helpers/helpers";
import useGetCalendarVisits from "../../../hooks/data_loaders/useGetCalendarVisits";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import VisitDispositionEnum from "common/enums/Calendaring/Visits/VisitDispositionEnum";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";
import { Flexbox } from "../../../styling/NewStyleComponents";
import FeatureFlags from "common/config/FeatureFlags";
import StartCareFlow from "../../../components/ScheduleToday/StartCareFlow";
import { canSeeCareFlow } from "common/enums/RolesEnum";

function checkIsMemberAppointment(appointment_type: AppointmentTypeEnum) {
  return (
    appointment_type === AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP ||
    appointment_type === AppointmentTypeEnum.NURSE_FOLLOWUP ||
    appointment_type === AppointmentTypeEnum.PROVIDER_FOLLOWUP
  );
}
interface IProps {
  staffId?: string;
  memberId?: string;
  startDate: DateTime;
  endDate: DateTime;
  isStaffDetails: boolean;
  staffTimezone?: string;
}

const UpcomingAppointments = ({
  memberId,
  staffId,
  startDate,
  endDate,
  isStaffDetails,
  staffTimezone
}: IProps) => {
  const navigate = useNavigate();
  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const [
    nurseProviderUpdateOutcomeModalOpen,
    setNurseProviderUpdateOutcomeModalOpen
  ] = useState<boolean>(false);

  const [selectedMemberId, setSelectedMemberId] = useState<string>("");
  const [selectedAppointmentStart, setSelectedAppointmentStart] =
    useState<string>("");

  /** delete the below lines when backend is updated */
  const {
    data: calendarVisitsData,
    isFetching: calendarVisitsIsFetching,
    error: calendarVisitsError
  } = useGetCalendarVisits({
    patient_id: memberId,
    staff_id: staffId,
    // we need to change this to the start of the day to grab PTO for the day
    startdate: startDate.startOf("day"),
    enddate: endDate
  });

  // filter out past appointments from upcoming by end date
  const filteredUpcomingAppointmentsData = useMemo(() => {
    const ptoArray = [];
    const filteredData = calendarVisitsData?.filter((item) => {
      const hasCompletedVisit =
        item?.visits?.findIndex(
          (visit) => visit?.status === VisitStatusEnum.COMPLETED
        ) > -1;
      const hasCompletedDisposition =
        getDispositionIndexInVisits(
          item?.visits,
          VisitDispositionEnum.COMPLETED
        ) > -1;
      const hasNoShowDisposition =
        getDispositionIndexInVisits(
          item?.visits,
          VisitDispositionEnum.NO_SHOW
        ) > -1;
      const hasTnOooDisposition =
        getDispositionIndexInVisits(item?.visits, VisitDispositionEnum.TN_OOO) >
        -1;
      const hasNoCallDisposition =
        getDispositionIndexInVisits(
          item?.visits,
          VisitDispositionEnum.NO_CALL
        ) > -1;

      const start = DateTime.fromISO(item.startdate);
      // subtract an hour -  an appointment is upcoming if it is happening now
      const upcomingFilterStart = startDate.minus({ hours: 1 });

      const shouldbeFilteredOut =
        start.diff(upcomingFilterStart).milliseconds < 0;

      const end = DateTime.fromISO(item.enddate);
      const isMemberAppointment = checkIsMemberAppointment(
        item?.appointment_type
      );

      if (!isMemberAppointment) {
        // add pto to an array
        ptoArray.push({ ...item, start, end });
      }

      return (
        !shouldbeFilteredOut &&
        // filter out non-member appointments
        isMemberAppointment &&
        // is in the future
        end.diffNow().milliseconds >= 0 &&
        // and does not have a completed/no_show visit or disposition
        !(
          hasCompletedVisit ||
          hasCompletedDisposition ||
          hasNoShowDisposition ||
          hasTnOooDisposition ||
          hasNoCallDisposition
        )
      );
    });

    return filteredData?.filter((item) => {
      const itemStart = DateTime.fromISO(item.startdate);
      const itemEnd = DateTime.fromISO(item.enddate);
      for (let i = 0; i < ptoArray.length; i++) {
        const ptoStart = ptoArray[i].start;
        const ptoEnd = ptoArray[i].end;

        const isStartInPtoTime =
          ptoStart.diff(itemStart).milliseconds < 0 &&
          ptoEnd.diff(itemStart).milliseconds > 0;
        const isEndInPtoTime =
          ptoStart.diff(itemEnd).milliseconds < 0 &&
          ptoEnd.diff(itemEnd).milliseconds > 0;

        if (isStartInPtoTime || isEndInPtoTime) {
          // hide event if it is in PTO time
          return false;
        }
      }
      return true;
    });
  }, [calendarVisitsData]);

  // find today's appointments
  const todaysUpcomingAppointmentsData = useMemo(() => {
    return calendarVisitsData?.filter((item) => {
      const itemStart = DateTime.fromISO(item.startdate);
      return itemStart.hasSame(DateTime.local(), "day");
    });
  }, [calendarVisitsData]);

  const appointmentMetWithColumn = isStaffDetails
    ? { name: "appointmentMemberAttendee", size: 165 }
    : { name: "appointmentStaffMember", size: 165 };

  /** delete the above lines when backend is updated */

  // Rolling back to the previous version of the code, comment this back in when backend is updated

  // const {
  //   data: upcomingPatientAppointmentsData,
  //   isFetching: upcomingPatientAppointmentsIsFetching,
  //   error: upcomingPatientAppointmentsError
  // } = useGetPatientAppointmentsQuery(
  //   {
  //     patient_id: memberId,
  //     startdate: startDate,
  //     enddate: endDate,
  //     sort: "asc"
  //   },
  //   { skip: isFalsy(memberId) || !checkIdValid(memberId) }
  // );

  // const {
  //   data: upcomingStaffAppointmentsData,
  //   isFetching: upcomingStaffAppointmentsIsFetching,
  //   error: upcomingStaffAppointmentsError
  // } = useGetCalendarVisits({
  //   staff_id: staffId,
  //   // subtract an hour -  an appointment is upcoming if it is happening now
  //   startdate: startDate.minus({ hours: 1 }),
  //   enddate: endDate,
  //   skip: isFalsy(staffId) || !checkIdValid(staffId)
  // });

  // // filter out past appointments from upcoming by end date
  // const filteredUpcomingAppointmentsData = useMemo(() => {
  //   if (upcomingPatientAppointmentsData) {
  //     return upcomingPatientAppointmentsData?.filter((item) => {
  //       const end = DateTime.fromISO(item?.event?.enddate);
  //       return end.diffNow().milliseconds >= 0;
  //     });
  //   }
  //   if (upcomingStaffAppointmentsData) {
  //     return upcomingStaffAppointmentsData?.filter((item) => {
  //       // change this to item?.event?.enddate when switching back to appointments
  //       const end = DateTime.fromISO(item?.enddate);
  //       return end.diffNow().milliseconds >= 0;
  //     });
  //   }

  //   return null;
  // }, [upcomingPatientAppointmentsData, upcomingStaffAppointmentsData]);

  // const appointmentStartEndColumn = isStaffDetails
  //   ? { name: "staffCalendarStartEnd", size: 130 }
  //   : { name: "appointmentStartEnd", size: 130 };

  // const appointmentMetWithColumn = isStaffDetails
  //   ? { name: "appointmentMemberAttendee", size: 165 }
  //   : { name: "appointmentStaffMember", size: 165 };

  // const appointmentActionsColumn = isStaffDetails
  //   ? { name: "calendarVisitsActions", size: 190 }
  //   : { name: "appointmentActions", size: 190 };

  // tbd fix this - the nurse provider changes need some code updates to get them working again because we are rolling back code

  // we can also use a redux slice for this functionality if we need more values
  const setNurseProviderModalOpen = useCallback(
    (shouldOpen: boolean, memberId?: string, encounter_started_on?: string) => {
      if (encounter_started_on) {
        setSelectedAppointmentStart(encounter_started_on);
      }
      if (shouldOpen && memberId) {
        setSelectedMemberId(memberId);
        setNurseProviderUpdateOutcomeModalOpen(shouldOpen);
      } else {
        setSelectedMemberId("");
        setSelectedAppointmentStart("");
        setNurseProviderUpdateOutcomeModalOpen(shouldOpen);
      }
    },
    []
  );

  return (
    <Box mb="32px">
      {memberId &&
        canSeeCareFlow(currentRole) &&
        FeatureFlags(user?.user_id).CARE_FLOWS &&
        todaysUpcomingAppointmentsData &&
        todaysUpcomingAppointmentsData.length > 0 && (
          <>
            <Typography variant="h4" color="text.secondary" mb="12px">
              Today's Appointments
            </Typography>
            {todaysUpcomingAppointmentsData.map((item) => {
              const timezone = staffTimezone ?? item.attendees[0]?.timezone;

              const startDate = DateTime.fromISO(item.startdate).setZone(
                timezone
              );
              const endDate = DateTime.fromISO(item.enddate).setZone(timezone);

              return (
                <Flexbox
                  flexDirection={"column"}
                  sx={{
                    maxWidth: "400px",
                    backgroundColor: "#f1f9ff",
                    padding: "10px",
                    border: 1,
                    borderColor: "#4c97ca",
                    borderRadius: "4px",
                    marginBottom: "10px"
                  }}
                >
                  <Flexbox
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignContent={"center"}
                  >
                    <Flexbox flexDirection={"column"}>
                      <Typography
                        variant="body1"
                        fontWeight="700"
                        color="primary.main"
                      >
                        {item.staff.first} {item.staff.last}
                      </Typography>
                      <Typography variant="body1" color="primary.main">
                        {startDate.toFormat("h:mm")} -{" "}
                        {endDate.toFormat("h:mm a ZZZZ")}
                      </Typography>
                    </Flexbox>

                    <StartCareFlow
                      isSameDay={true}
                      attendee={item.attendees[0]}
                      visitsRequest={{
                        calendar_event_start: startDate,
                        calendar_event_end: endDate,
                        staff_id: item.staff.id,
                        patient_id: item.attendees[0].attendee_id,
                        calendar_id: item.event_id
                      }}
                    />
                  </Flexbox>
                </Flexbox>
              );
            })}
          </>
        )}

      <Typography variant="h4" color="text.secondary" mb="12px">
        Upcoming Appointments
      </Typography>
      {calendarVisitsIsFetching && <LoadingFallback count={5} />}
      {filteredUpcomingAppointmentsData && !calendarVisitsIsFetching && (
        <Table
          noDataText="There are no scheduled appointments."
          noDataButtonText={memberId && "Schedule appointment"}
          noDataButtonOnClick={() =>
            navigate(`/members/memberId/${memberId}/new-appointment`)
          }
          tableColumns={[
            {
              name: "calendarVisitsStartEnd",
              size: 130
            },
            // {
            //   name: "appointmentType",
            //   size: 150,
            // },
            appointmentMetWithColumn,
            { name: "calendarVisitsActions", size: 190 }
          ]}
          tableProps={{
            navigate,
            staffTimezone,
            currentRole
          }}
          data={filteredUpcomingAppointmentsData}
        />
      )}

      {calendarVisitsError && <ErrorComponent error={calendarVisitsError} />}

      {/* comment in the below lines when backend is updated */}
      {/* {(upcomingPatientAppointmentsIsFetching ||
        upcomingStaffAppointmentsIsFetching) && <LoadingFallback count={5} />}
      {filteredUpcomingAppointmentsData &&
        !(
          upcomingPatientAppointmentsIsFetching ||
          upcomingStaffAppointmentsIsFetching
        ) && (
          <Table
            noDataText="No upcoming appointments found."
            tableColumns={[
              appointmentStartEndColumn,
              appointmentMetWithColumn,
              appointmentActionsColumn
            ]}
            tableProps={{
              navigate,
              currentRole,
              setNurseProviderModalOpen
            }}
            data={filteredUpcomingAppointmentsData}
          />
        )}
      <UpdateOutcomeNurseProviderModal
        key="update-outcome-nurse-provider-modal-upcoming"
        memberId={selectedMemberId}
        modalOpen={nurseProviderUpdateOutcomeModalOpen}
        setModalOpen={setNurseProviderModalOpen}
        encounter_started_on={selectedAppointmentStart}
      />
      {upcomingPatientAppointmentsError && (
        <ErrorComponent error={upcomingPatientAppointmentsError} />
      )}
      {upcomingStaffAppointmentsError && (
        <ErrorComponent error={upcomingStaffAppointmentsError} />
      )} */}
    </Box>
  );
};

export default UpcomingAppointments;
