import { Box, Button, styled, Typography } from "@mui/material";
import { getNameOrUsername } from "common/helpers/helpers";
import useGetCurrentVisit from "common/hooks/useGetCurrentVisit";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import FeatureFlags from "common/config/FeatureFlags";

const VisitContainer = styled(Box)`
  display: flex;
  background-color: ${({ theme }) => theme.palette.primary.main};
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 5px;
`;

const WhiteButton = styled(Button)`
  background-color: white;
`;

const VisitInProgress = ({ staffId }: { staffId: string }) => {
  if (!FeatureFlags(staffId).CARE_FLOWS) return;

  const navigate = useNavigate();
  const { data } = useGetCurrentVisit({ staffId });

  const { data: member } = useGetMemberWithUsernameQuery(
    { username: data?.patient_id },
    { skip: data === undefined }
  );

  const handleResumeClick = () => {
    navigate(`/visits/${data?.visit_id}`);
  };

  const elapsedTimeString = useMemo(() => {
    const totalSeconds =
      data?.care_flow?.sections?.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue?.actual_time_seconds || 0,
        0
      ) ?? 0;

    return DateTime.fromSeconds(totalSeconds ?? 0).toFormat("mm:ss");
  }, [data]);

  if (data === undefined) return;

  return (
    <VisitContainer margin={"10px 2.5%"}>
      <Box display={"flex"} alignItems={"center"} gap={1}>
        <Typography variant="h6" color={"white"}>
          Timer
        </Typography>
        <Typography variant="body1" color={"white"}>
          {elapsedTimeString}
        </Typography>
      </Box>
      {member && (
        <Typography variant="h6" color={"white"}>
          Task in Progress for {getNameOrUsername(member?.patient)}
        </Typography>
      )}
      <WhiteButton onClick={handleResumeClick}>Resume</WhiteButton>
    </VisitContainer>
  );
};

export default VisitInProgress;
