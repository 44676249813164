import { CircularProgress, Typography } from "@mui/material";
import { useGetVisitNotesQuery } from "common/services/VisitsService";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { gray } from "../../../styling/colors";
import { useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { Column } from "../../../styling/StyleComponents";
import { RootState, useAppDispatch } from "common/redux";
import { setGeneratedNotes } from "common/redux/VisitsSlice";
import { isFalsy } from "common/helpers/helpers";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

const GeneratedNotes = styled(Typography)`
  h1 {
    margin: 25px 0px;
  }
`;

interface IProps {
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
}

const VisitFieldGenerateNotes = ({ field, visit }: IProps) => {
  const dispatch = useAppDispatch();
  const { data, isLoading, refetch } = useGetVisitNotesQuery({
    visit_id: visit.visit_id
  });

  const { dirty } = useSelector((state: RootState) => state.visits);

  const [dateTimeGenerated, setDateTimeGenerated] = useState<DateTime>();

  useEffect(() => {
    if (data) {
      setDateTimeGenerated(DateTime.now());
      dispatch(setGeneratedNotes(data.notes));
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [dirty]);

  const style = useMemo(() => {
    if (isFalsy(data)) return {};

    if (data.format === "html") {
      return {
        dangerouslySetInnerHTML: { __html: data.notes }
      };
    } else {
      return { whiteSpace: "pre-wrap" };
    }
  }, [data]);

  return (
    <Column>
      {isLoading && <CircularProgress />}
      {data && (
        <GeneratedNotes variant="body1" color={gray[900]} {...style}></GeneratedNotes>
      )}
      {dateTimeGenerated && (
        <Typography alignSelf={"flex-end"} variant="body1" color={gray[900]}>
          Generated {dateTimeGenerated.toRelative()}
        </Typography>
      )}
    </Column>
  );
};

export default VisitFieldGenerateNotes;
