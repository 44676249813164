import { useState } from "react";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { Button, Typography, styled } from "@mui/material";

import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { RootState } from "common/redux";
import RolesEnum, {
  canCreatePastEncounter,
  isProviderRole
} from "common/enums/RolesEnum";

import MemberDetailsHeader from "./Header/MemberDetailsHeader";
import MemberDetailsLayout from "./MemberDetailsLayout";
import { Flexbox } from "../../styling/NewStyleComponents";

import Table from "../../components/Table/Table";
import AddPastEncounterModal from "../PatientData/AddPastEncounterModal";
import { checkIdValid, isFalsy } from "common/helpers/helpers";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import ErrorComponent from "../../components/ErrorComponent";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import DeleteEncounterConfirmationModal from "./DeleteEncounterConfirmationModal";
import { SubmitEncounterModal } from "./Header/SubmitEncounter/SubmitEncounterModal";
import { NewProviderEncounterReasons } from "common/helpers/EncounterHelper";
import { useNavigate } from "react-router-dom";

// old endpoint
import { useGetEncountersQuery } from "common/services/EncountersService";
import EncounterLinkedEntitiesEnum from "common/enums/EncounterLinkedEntitiesEnum";

// new endpoint
// import { useGetEncounterVisitsQuery } from "common/services/VisitsService";

const MemberDetailsEncounterContainer = styled("div")`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const EncountersTitleContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

function getEncounterColumns(currentRole) {
  const arr = [
    { name: "encounterType", size: 240 },
    { name: "encounterDurationEditable", size: 120, editable: true },
    { name: "encounterDateV2", size: 120 },
    { name: "encounterSubmitterV2", size: 250 }
  ];

  if (isProviderRole(currentRole) || currentRole === RolesEnum.ADMIN) {
    arr.push({
      name: "encounterModality",
      size: 90
    });
    arr.push({
      name: "encounterComplexity",
      size: 130
    });
  }

  arr.push({ name: "encounterActions", size: 250 });

  return arr;
}

const MemberDetailsEncounters = ({
  hideReadings = false
}: {
  hideReadings?: boolean;
}) => {
  const navigate = useNavigate();
  const params = useSanitizedParams();
  const { memberId } = params;
  const isValidId = checkIdValid(memberId);

  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const encounterColumns = getEncounterColumns(currentRole);

  const [addPastEncounterModalVisible, setAddPastEncounterModalVisible] =
    useState<boolean>(false);

  const [editPastEncounterModalVisible, setEditPastEncounterModalVisible] =
    useState<boolean>(false);

  const [selectedEncounter, setSelectedEncounter] = useState(undefined);

  const setEditModal = (visible: boolean) => {
    if (!visible) setSelectedEncounter(undefined);
    setEditPastEncounterModalVisible(visible);
  };

  const { data: patient } = useGetMemberWithUsernameQuery(
    {
      username: memberId
    },
    { skip: isValidId === false }
  );

  const startDate = DateTime.fromFormat("2021-01-01", "yyyy-MM-dd").startOf(
    "day"
  );

  const {
    data: encounterVisits,
    isFetching: encounterVisitsIsFetching,
    error: encounterVisitsError
  } = useGetEncountersQuery(
    // new endpoint
    // } = useGetEncounterVisitsQuery(
    {
      patient_id: memberId,
      startsAfter: startDate,
      linked_entities: [EncounterLinkedEntitiesEnum.CARE_FLOW]
      // param for encounter-visit endpoint - comment this back in when we are ready to use thisparam for new endpoint
      // encounter_starts_on_after: startDate
    },
    { skip: isValidId === false }
  );

  const [isDeleteEncounterModalOpen, setIsDeleteEncounterModalOpen] =
    useState<boolean>(false);

  const [selectedEncounterId, setSelectedEncounterId] = useState<string>("");

  const handleDeleteEncounter = (encounter_id: string) => {
    setSelectedEncounterId(encounter_id);
    setIsDeleteEncounterModalOpen(true);
  };

  const closeDeleteEncounterModal = () => {
    setIsDeleteEncounterModalOpen(false);
    setSelectedEncounterId("");
  };

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );

  return (
    <MemberDetailsEncounterContainer>
      <Flexbox
        flexDirection="column"
        height="inherit"
        width="inherit"
        gap="16px"
        overflow="hidden"
      >
        <MemberDetailsHeader key={`${memberId}-header-encounters`} />
        <MemberDetailsLayout
          key={`${memberId}-layout-encounters`}
          memberId={memberId}
          hideReadings={hideReadings}
        >
          <br />
          {canCreatePastEncounter(currentRole) && (
            <Button
              variant="outlined"
              onClick={() => setAddPastEncounterModalVisible(true)}
            >
              Add Past Encounter
            </Button>
          )}
          <EncountersTitleContainer>
            <Typography variant="h4" mt="16px">
              Encounters History
            </Typography>
          </EncountersTitleContainer>
          {encounterVisits && !encounterVisitsIsFetching && (
            <Table
              tableColumns={encounterColumns}
              data={encounterVisits}
              tableProps={{
                navigate,
                handleDeleteEncounter,
                currentRole,
                currentUserId: user?.user_id,
                setSelectedEncounter,
                setEditPastEncounterModalVisible
              }}
            />
          )}
          {encounterVisitsIsFetching && <LoadingFallback count={10} />}
          {encounterVisitsError && (
            <ErrorComponent error={encounterVisitsError} />
          )}
        </MemberDetailsLayout>

        {/* We are only using this for NP encounters right now */}
        <SubmitEncounterModal
          key={`editEncounter-${selectedEncounter?.encounter_id}`}
          modalOpen={
            editPastEncounterModalVisible && !isFalsy(selectedEncounter)
          }
          setModalOpen={setEditModal}
          memberId={memberId}
          isProvider={true}
          encounterReasons={NewProviderEncounterReasons}
          memberName={selectedEncounter?.member_name}
          default_total_time={selectedEncounter?.total_time}
          default_visit_type={selectedEncounter?.visit_type}
          default_modality={selectedEncounter?.modality}
          default_complexity={selectedEncounter?.complexity}
          selected_encounter_id={selectedEncounter?.encounter_id}
          hasProviderSubmittedEncounter={true}
          hasInvalidProviderAssigned={false}
        />

        <AddPastEncounterModal
          patient={patient}
          isOpen={addPastEncounterModalVisible}
          onRequestClose={() => setAddPastEncounterModalVisible(false)}
          historicalEncounters={encounterVisits}
        />

        <DeleteEncounterConfirmationModal
          isOpen={isDeleteEncounterModalOpen}
          onRequestClose={() => closeDeleteEncounterModal()}
          encounterId={selectedEncounterId}
        />
      </Flexbox>
    </MemberDetailsEncounterContainer>
  );
};

export default MemberDetailsEncounters;
