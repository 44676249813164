import { useState } from "react";

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import SmsIcon from "@mui/icons-material/Sms";
import { LoadingButton } from "@mui/lab";
import { DateTime } from "luxon";

import MemberType from "common/types/MemberType";
import { useSendJoinVideoCallMessageMutation } from "common/services/MemberEngagementService";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import { setAnswer, setSelectedSection } from "common/redux/VisitsSlice";
import { RootState, useAppDispatch } from "common/redux";

import { blue, gray } from "../../../styling/colors";
import { callPatientModalSelector } from "../../../helpers/helpers";
import { Phone } from "@mui/icons-material";
import { CustomTooltip } from "../../../styling/StyleComponents";
import { Alert_closeAll, Alert_show } from "common/helpers/AlertHelper";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";

const RowContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 10px 0px; 0px; 0px;
`;

const StepContainer = styled("div")`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
`;

const HorizontalSpacing = styled("div")`
  width: 64px;
  height: 1px;
  background-color: ${gray[300]};
`;

const BubbleContainer = styled("div")`
  display: flex;
  align-self: start;
  background-color: #007aff;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  max-width: 450px;
`;

interface IProps {
  member: MemberType;
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
}

const CHECKBOX_CONNECTED = "Connected";
const CHECKBOX_NOT_CONNECTED = "Not Connected";

const RenderStep = ({
  step,
  disabled
}: {
  step: string | number;
  disabled: boolean;
}) => {
  const backgroundColor = disabled ? gray[200] : blue[200];
  const textColor = disabled ? gray[400] : gray[900];
  return (
    <StepContainer sx={{ backgroundColor }}>
      <Typography variant="h6" sx={{ color: textColor }}>
        {step}
      </Typography>
    </StepContainer>
  );
};

const VisitFieldConnectWithMember = ({ member, field, visit }: IProps) => {
  const dispatch = useAppDispatch();

  const { answers } = useSelector((state: RootState) => state.visits);

  const [sentSmsDate, setSentSmsDate] = useState<DateTime>(null);
  const [step, setStep] = useState<number>(0);
  const [sendJoinVideoCallMessageMutation, { data, isLoading }] =
    useSendJoinVideoCallMessageMutation();

  const handleSmsText = async () => {
    await sendJoinVideoCallMessageMutation({
      member_id: member?.patient?.patient_id,
      action: "ON_DEMAND_APPOINTMENT_REMINDER"
    });

    setSentSmsDate(DateTime.now());
  };

  const disabled = visit.status !== VisitStatusEnum.IN_PROGRESS;

  return (
    <Container>
      <Typography variant="body1">
        To start the appointment, call the member. If they don't answer, click{" "}
        <b>'Send Text'</b> to send a message, then click <b>'Call Again'</b> to
        retry the call.
      </Typography>
      <br />
      <RowContainer>
        <RenderStep step={1} disabled={disabled || step < 0} />
        <CustomTooltip title={"Calls the member from Zoom"} placement="top">
          <div>
            <Button
              variant="contained"
              onClick={() => {
                setStep(1);
                callPatientModalSelector(dispatch, member?.patient);
              }}
              startIcon={<Phone />}
              disabled={disabled || step < 0}
            >
              Call
            </Button>
          </div>
        </CustomTooltip>

        <HorizontalSpacing />

        <RenderStep step={2} disabled={disabled || step < 1} />
        <CustomTooltip
          title={
            "Click to automatically send an appointment reminder to the member"
          }
          placement="top"
        >
          <div>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              disabled={disabled || step < 1}
              onClick={() => {
                setStep(2);
                handleSmsText();
              }}
              startIcon={<SmsIcon />}
            >
              Send Text
            </LoadingButton>
          </div>
        </CustomTooltip>

        <HorizontalSpacing />

        <RenderStep step={3} disabled={disabled || step < 2} />
        <CustomTooltip
          title={
            sentSmsDate &&
            `Successfully sent the below text message ${sentSmsDate.toRelative()}`
          }
          placement="top"
        >
          <div>
            <Button
              variant="contained"
              disabled={disabled || step < 2}
              onClick={() => {
                callPatientModalSelector(dispatch, member?.patient);
              }}
              startIcon={<Phone />}
            >
              Call
            </Button>
          </div>
        </CustomTooltip>
      </RowContainer>

      {sentSmsDate && (
        <>
          <br />
          <Typography
            variant="body1"
            color="green"
          >{`Successfully sent the below text message ${sentSmsDate.toRelative()}`}</Typography>
        </>
      )}

      {data?.MessageBody && (
        <BubbleContainer>
          <Typography color="white">{data?.MessageBody}</Typography>
        </BubbleContainer>
      )}
      <br />
      <Typography variant="body1" color={gray[900]}>
        Were you able to connect?
      </Typography>

      <RadioGroup
        name="radio-buttons-group"
        value={answers[field.field_id] ?? ""}
        row
        onChange={(event) => {
          dispatch(
            setAnswer({ id: field.field_id, value: event.target.value })
          );

          if (event.target.value === CHECKBOX_NOT_CONNECTED) {
            Alert_show({
              dispatch,
              title: "Please Review",
              content: (
                <>
                  <Typography
                    variant="h6"
                    color={"secondary"}
                    textAlign={"center"}
                  >
                    Were you not able to connect with the member? If so, confirm
                    below and proceed to the next section.
                  </Typography>
                </>
              ),
              size: "small",
              hideCloseIcon: true,
              buttons: [
                {
                  text: "Confirm",
                  onPress: () => {
                    Alert_closeAll(dispatch);

                    const newSection = visit?.care_flow?.sections.find(
                      (item) => item.name === "Post-Call"
                    );

                    dispatch(
                      setSelectedSection({
                        section_id: newSection.section_id,
                        subsection_id: newSection.subsections[0].section_id
                      })
                    );
                  }
                },
                {
                  style: "cancel",
                  text: "Cancel",
                  onPress: () => Alert_closeAll(dispatch)
                }
              ]
            });
          }
        }}
      >
        <FormControlLabel
          key={CHECKBOX_CONNECTED}
          value={CHECKBOX_CONNECTED}
          control={<Radio />}
          label={CHECKBOX_CONNECTED}
          disabled={disabled}
        />

        <FormControlLabel
          key={CHECKBOX_NOT_CONNECTED}
          value={CHECKBOX_NOT_CONNECTED}
          control={<Radio />}
          label={CHECKBOX_NOT_CONNECTED}
          disabled={disabled}
        />
      </RadioGroup>
    </Container>
  );
};

export default VisitFieldConnectWithMember;
