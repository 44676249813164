import { useMemo } from "react";
import { useGetVisitQuery, useGetVisitsQuery } from "../services/VisitsService";
import { isFalsy } from "../helpers/helpers";
import VisitStatusEnum from "../enums/Calendaring/Visits/VisitStatusEnum";

interface IProps {
  memberId?: string;
  staffId?: string;
}

const useGetCurrentVisit = ({ memberId, staffId }: IProps) => {
  const {
    data,
    isLoading: isLoadingVisits,
    error: errorVisits
  } = useGetVisitsQuery(
    {
      patient_id: memberId,
      staff_id: staffId,
      status: VisitStatusEnum.IN_PROGRESS,
      with_care_flow: true
    },
    { skip: staffId === undefined && memberId === undefined }
  );

  const ongoingVisit = useMemo(() => {
    if (isFalsy(data)) return;

    return data.find((item) => item.status === VisitStatusEnum.IN_PROGRESS && item.care_flow);
  }, [data]);

  const {
    data: visitWithCareFlow,
    isLoading: isLoadingVisit,
    error: errorVisit
  } = useGetVisitQuery(
    { visit_id: ongoingVisit?.visit_id },
    {
      skip: ongoingVisit === undefined
    }
  );

  return {
    data: visitWithCareFlow,
    isLoading: isLoadingVisits || isLoadingVisit,
    error: errorVisits ?? errorVisit
  };
};

export default useGetCurrentVisit;
