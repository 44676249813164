import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography
} from "@mui/material";

import CareFlowFieldTypeEnum from "common/enums/CareFlowFieldTypeEnum";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { RootState, useAppDispatch } from "common/redux";
import { setAnswer } from "common/redux/VisitsSlice";
import { gray } from "../../../styling/colors";
import VisitContentComponentField from "./VisitContentComponentField";
import { Flexbox } from "../../../styling/NewStyleComponents";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";

const FieldContainer = styled("div")`
  flex: 1;
`;

const Row = styled(Flexbox)`
  display: flex;
  align-items: center;
`;

const MultiFieldContainer = styled("div")`
  display: block;
  column-count: 2;
  flex-wrap: wrap;
  gap: 20px;
`;

const RenderFieldValue = ({
  field,
  width,
  visit
}: {
  field: CareFlowFieldType;
  width?: number;
  visit: GetVisitResponseType;
}) => {
  const dispatch = useAppDispatch();

  const { answers } = useSelector((state: RootState) => state.visits);

  const disabled = visit.status !== VisitStatusEnum.IN_PROGRESS;

  switch (field.type) {
    case CareFlowFieldTypeEnum.CHECKBOX:
      let isChecked = answers[field.field_id] === "true";
      // If a checkbox has a child subfield, only show if isChecked
      const hasChild = field?.subfields?.length > 0;
      return (
        <>
          <FormControlLabel
            control={<Checkbox checked={isChecked} />}
            onChange={(event, checked) => {
              dispatch(
                setAnswer({
                  id: field.field_id,
                  value: "" + checked
                })
              );

              // If unchecked, unset children field values
              if (!isChecked && hasChild) {
                field.subfields.forEach((item) => {
                  dispatch(
                    setAnswer({
                      id: item.field_id,
                      value: ""
                    })
                  );
                });
              }
            }}
            label={field.label}
            disabled={disabled}
            slotProps={{
              typography: {
                variant: "body1",
                color: gray[900]
              }
            }}
          />
          {isChecked &&
            hasChild &&
            field.subfields.map((item) => {
              return (
                <Fragment key={item.field_id}>
                  <Typography
                    variant="h6"
                    color={gray[900]}
                    data-testid={item.name}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="body1" color={gray[900]}>
                    {item.description}
                  </Typography>
                  <RenderFieldValue field={item} visit={visit} />
                </Fragment>
              );
            })}
        </>
      );
    case CareFlowFieldTypeEnum.RADIO: {
      const optionsArray = field.options;
      let isYes = answers[field.field_id] === "Yes";
      // If a checkbox has a child subfield, only show if isChecked
      const hasChild = field?.subfields?.length > 0;
      return (
        <div>
          <Row>
            <Typography variant="body1" color={gray[900]}>
              {field.label}
            </Typography>
          </Row>
          <RadioGroup
            name="radio-buttons-group"
            defaultValue={answers[field.field_id] ?? ""}
            row
            onChange={(event) => {
              dispatch(
                setAnswer({ id: field.field_id, value: event.target.value })
              );
              // If unchecked, unset children field values
              if (!isYes && hasChild) {
                field.subfields.forEach((item) => {
                  dispatch(
                    setAnswer({
                      id: item.field_id,
                      value: ""
                    })
                  );
                });
              }
            }}
          >
            {optionsArray.map((option) => {
              return (
                <FormControlLabel
                  key={option.option}
                  value={option.option}
                  control={<Radio />}
                  disabled={disabled}
                  label={option.option}
                />
              );
            })}
          </RadioGroup>
          {isYes &&
            hasChild &&
            field.subfields.map((item) => {
              return (
                <Flexbox key={item.field_id} flexDirection={"column"} style={{margin: "10px 40px"}}>
                  <Typography
                    variant="h6"
                    color={gray[900]}
                    data-testid={item.name}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="body1" color={gray[900]} style={{marginBottom: "10px"}}>
                    {item.description}
                  </Typography>
                  <RenderFieldValue field={item} visit={visit} />
                </Flexbox>
              );
            })}
        </div>
      );
    }
    case CareFlowFieldTypeEnum.COMPONENT:
      return (
        <VisitContentComponentField field={field} width={width} visit={visit} />
      );
    case CareFlowFieldTypeEnum.LONG_INPUT:
      return (
        <TextField
          label={field.label}
          fullWidth
          multiline
          rows={3}
          value={answers[field.field_id] ?? ""}
          disabled={disabled}
          sx={{
            input: {
              color: gray[900]
            }
          }}
          onChange={(event) => {
            dispatch(
              setAnswer({ id: field.field_id, value: event.target.value })
            );
          }}
        />
      );
    case CareFlowFieldTypeEnum.MULTI:
      return (
        <div>
          <Typography variant="body1" color={gray[900]}>
            {field.label}
          </Typography>
          <MultiFieldContainer>
            {field.subfields.map((item) => {
              return (
                <Fragment key={item.field_id}>
                  <Typography
                    variant="h6"
                    color={gray[900]}
                    data-testid={item.name}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="body1" color={gray[900]}>
                    {item.description}
                  </Typography>
                  <br />
                  <RenderFieldValue field={item} visit={visit} />
                </Fragment>
              );
            })}
          </MultiFieldContainer>
        </div>
      );
    case CareFlowFieldTypeEnum.SELECT: {
      const optionsArray = field.options;
      return (
        <div>
          <TextField
            select
            label={field.label}
            placeholder={field.placeholder}
            disabled={disabled}
            value={answers[field.field_id] ?? ""}
            sx={{
              width: 300,
              input: {
                color: gray[900]
              }
            }}
            onChange={(event) =>
              dispatch(
                setAnswer({ id: field.field_id, value: event.target.value })
              )
            }
          >
            {optionsArray.map((item) => (
              <MenuItem key={item.option} value={item.option}>
                {item.option}
              </MenuItem>
            ))}
          </TextField>
        </div>
      );
    }
    case CareFlowFieldTypeEnum.MULTISELECT: {
      const optionsArray = field.options;
      const value = answers[field.field_id] as string;
      return (
        <div>
          <TextField
            select
            label={field.label}
            placeholder={field.placeholder}
            disabled={disabled}
            sx={{
              width: 300,
              input: {
                color: gray[900]
              }
            }}
            slotProps={{
              select: {
                variant: "outlined",
                multiple: true,
                value: value?.split(",") || [],
                onChange: (event) => {
                  const value = event.target.value as string[];
                  dispatch(
                    setAnswer({
                      id: field.field_id,
                      value: value.filter((item) => item.length > 0).join(",")
                    })
                  );
                }
              }
            }}
          >
            {optionsArray.map((item) => (
              <MenuItem key={field.field_id + "_" + item} value={item.option}>
                {item.option}
              </MenuItem>
            ))}
          </TextField>
        </div>
      );
    }
    case CareFlowFieldTypeEnum.SHORT_INPUT:
      return (
        <TextField
          fullWidth
          label={field.label}
          value={answers[field.field_id] ?? ""}
          placeholder={field.placeholder}
          disabled={disabled}
          sx={{
            input: {
              color: gray[900]
            }
          }}
          onChange={(event) => {
            dispatch(
              setAnswer({ id: field.field_id, value: event.target.value })
            );
          }}
        />
      );
    case CareFlowFieldTypeEnum.TEXT:
      return (
        <Typography variant="body1" color={gray[900]}>
          {field.label}
        </Typography>
      );
    default:
      return (
        <Typography variant="body1" color={gray[900]}>
          {field.type} IS UNDER CONSTRUCTION
        </Typography>
      );
  }
};

const VisitContentField = ({
  field,
  visit
}: {
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
}) => {
  const fieldContainerRef = useRef<HTMLDivElement>();

  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    setWidth(fieldContainerRef?.current?.offsetWidth);
  }, [fieldContainerRef.current]);

  return (
    <FieldContainer ref={fieldContainerRef}>
      {field.name && (
        <Typography
          variant="h6"
          color={gray[900]}
          textTransform={"uppercase"}
          margin={"15px 5px 10px 0px"}
        >
          {field.name}
          {field.name && field.is_required && <>*</>}
        </Typography>
      )}
      <Typography variant="body1" color={gray[900]} marginBottom={"10px"}>
        {field.description}
      </Typography>
      <RenderFieldValue field={field} width={width} visit={visit} />
    </FieldContainer>
  );
};

export default VisitContentField;
