import { useParams, useSearchParams } from "react-router-dom";
import { Typography, styled } from "@mui/material";
import VisitSections from "./VisitSections";
import { RowContainer } from "../MemberDetails/StartIntake/StyledComponents";
import VisitContent from "./VisitContent";

import { checkIdValid } from "common/helpers/helpers";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import {
  useGetVisitQuery,
  useUpdateVisitStateMutation
} from "common/services/VisitsService";
import ErrorComponent from "../../components/ErrorComponent";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { MemberHeader } from "../MemberDetails/Header/MemberHeader";
import { useEffect, useMemo, useState } from "react";
import { RootState, useAppDispatch } from "common/redux";
import { initialize, setSelectedSection } from "common/redux/VisitsSlice";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { VisitStateType } from "common/types/Visits/CareFlowStateType";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import { Alert_show } from "common/helpers/AlertHelper";

const Container = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  margin: 25px;
`;

const VisitFlow = () => {
  const { visitId } = useParams();
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const isValidId = checkIdValid(visitId);

  const [lastSaved, setLastSaved] = useState<DateTime>(null);

  const { answers, selectedSection, selectedSubSection, elapsedTime } =
    useSelector((state: RootState) => state.visits);

  const processedElapsedTime = useMemo(() => {
    let preCallTime = 0;
    let callTime = 0;
    let postCallTime = 0;
    for (const item of elapsedTime) {
      if (item.section_id === "PRE_CALL")
        preCallTime += item.actual_time_seconds;
      if (item.section_id === "CALL") callTime += item.actual_time_seconds;
      if (item.section_id === "POST_CALL")
        postCallTime += item.actual_time_seconds;
    }
    return [
      { subsection_id: "PRE_CALL", actual_time_seconds: preCallTime },
      { subsection_id: "CALL", actual_time_seconds: callTime },
      { subsection_id: "POST_CALL", actual_time_seconds: postCallTime },
      ...elapsedTime
    ];
  }, [elapsedTime]);

  const [
    updateVisitStateMutation,
    {
      isLoading: isUpdateVisitLoading,
      isSuccess: isUpdateVisitSuccess,
      isError: isUpdateVisitError,
      error: updateVisitError
    }
  ] = useUpdateVisitStateMutation();

  const {
    data: visit,
    isLoading: isVisitLoading,
    error: visitError
  } = useGetVisitQuery(
    {
      visit_id: visitId
    },
    { skip: visitId === undefined || isValidId === false }
  );

  const memberId = visit?.patient_id;
  const {
    data: member,
    isLoading: isMemberLoading,
    error: memberError
  } = useGetMemberWithUsernameQuery(
    {
      username: memberId,
      linked_entities: [
        MemberLinkedEntitiesEnum.NURSE,
        MemberLinkedEntitiesEnum.PROVIDER
      ]
    },
    { skip: memberId === undefined || isValidId === false }
  );

  useEffect(() => {
    if (isUpdateVisitError) {
      Alert_show({
        dispatch,
        title: "Error while saving visit",
        content: <ErrorComponent error={isUpdateVisitError} />,
        size: "small",
        type: "error"
      });
    }
  }, [isUpdateVisitError]);

  useEffect(() => {
    if (!visit?.care_flow) return;

    const subsection_id = searchParams.get("current_subsection");
    const section_id = searchParams.get("current_section_id");

    const foundSection = visit.care_flow.sections.find(
      (section) => section.section_id === section_id
    );

    if (foundSection) {
      const foundSubsection = foundSection.subsections.find(
        (subsection) => subsection.section_id === subsection_id
      );

      dispatch(
        setSelectedSection({
          subsection_id: foundSubsection
            ? foundSubsection.section_id
            : foundSection.subsections[0].section_id,
          section_id: foundSection.section_id
        })
      );
    } else {
      const firstSection = visit.care_flow.sections[0];
      const firstSubSection = firstSection?.subsections[0];
      dispatch(
        setSelectedSection({
          subsection_id: firstSubSection?.section_id,
          section_id: firstSection?.section_id
        })
      );
    }
  }, [visit]);

  useEffect(() => {
    if (!visit) return;

    setSearchParams({
      current_section_id: selectedSection,
      current_subsection: selectedSubSection
    });
  }, [visit, selectedSection, selectedSubSection]);

  useEffect(() => {
    if (!visit) return;

    dispatch(initialize(visit));
  }, [visit]);

  useEffect(() => {
    if (updateVisitError) {
      // @ts-ignore
      const errorResponseMessage = updateVisitError?.response?.data?.message;
      Alert_show({
        dispatch,
        size: "small",
        title: "Error when saving visit",
        content: (
          <ErrorComponent error={errorResponseMessage ?? updateVisitError} />
        )
      });
    }
  }, [updateVisitError]);

  const syncCareFlowToAPI = async (visit_state?: VisitStateType) => {
    if (visit.status !== VisitStatusEnum.IN_PROGRESS) return;
    const fieldValues = Object.entries(answers)?.map((item) => {
      return {
        field_id: item[0],
        value: item[1].toString()
      };
    });

    await updateVisitStateMutation({
      visit_id: visit.visit_id,
      body: {
        visit_state,
        care_flow_state: {
          current_section: selectedSection,
          current_subsection: selectedSubSection,
          time_spent: processedElapsedTime?.map(
            ({ subsection_id, actual_time_seconds }) => {
              return {
                section_id: subsection_id,
                actual_time_seconds
              };
            }
          ),
          field_values: fieldValues
        }
      }
    });

    setLastSaved(DateTime.now());
  };

  if (!isValidId)
    return (
      <Container>
        <Typography variant="body1">{`Invalid Visit ID ${visitId}`}</Typography>
      </Container>
    );

  return (
    <Container>
      {isVisitLoading || isMemberLoading ? (
        <LoadingFallback count={3} />
      ) : (
        <>
          <MemberHeader
            key={`${visit?.patient_id}-header-visits`}
            memberId={visit?.patient_id}
            patient={member}
            isPatientError={memberError}
            hideButtons
            hideStartEncounter
          />
          <br />
          {visit?.care_flow ? (
            <RowContainer
              flex={1}
              gap={"20px"}
              sx={{ alignItems: "flex-start" }}
            >
              <VisitSections
                visit={visit}
                isUpdateVisitLoading={isUpdateVisitLoading}
                lastSaved={lastSaved}
              />
              <VisitContent
                visit={visit}
                syncCareFlowToAPI={syncCareFlowToAPI}
                isUpdateVisitSuccess={isUpdateVisitSuccess}
                isUpdateVisitLoading={isUpdateVisitLoading}
              />
            </RowContainer>
          ) : (
            <ErrorComponent
              error={`Visit ID: ${visitId} does not have a careflow`}
            />
          )}
          <ErrorComponent error={visitError} />
        </>
      )}
    </Container>
  );
};

export default VisitFlow;
