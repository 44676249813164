import FeatureFlags from "common/config/FeatureFlags";
import { CustomTooltip } from "../../styling/StyleComponents";
import useGetCurrentVisit from "common/hooks/useGetCurrentVisit";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import styled from "@emotion/styled";
import { gray } from "../../styling/colors";
import { Button } from "@mui/material";
import { AttendeeType } from "common/types/Calendaring/CalendarEventResponseType";
import { useEffect, useState } from "react";
import VisitMotivationTypesEnum from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import { useCreateVisitMutation } from "common/services/VisitsService";
import VisitTypeModal from "../../pages/AllUserList/NursesSchedules/VisitTypeModal";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "common/redux";
import { isFalsy } from "common/helpers/helpers";
import replace from "lodash.replace";
import { CAREFLOW_PATH } from "../../routes/RouteComponents";
import { useNavigate } from "react-router-dom";
import { setInitialState } from "common/redux/VisitsSlice";

const StyledButton = styled(Button)`
  background: white;
  padding: 5px;
  &:hover: {
    backgroundColor: ${gray[25]}
    pointer: cursor;
  }
  lineHeight: 1.3;
`;

const StartCareFlow = ({
  isSameDay,
  visitsRequest,
  attendee
}: {
  isSameDay: boolean;
  visitsRequest: any;
  attendee: AttendeeType;
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { data: currentVisit, isLoading } = useGetCurrentVisit({
    staffId: user?.user_id
  });

  const [visitModalAttendee, setVisitModalAttendee] = useState<{
    attendee: AttendeeType;
    visitsRequest: any;
  }>(null);

  const [
    createVisitMutation,
    {
      error: createVisitError,
      isSuccess: createVisitIsSuccess,
      isLoading: createVisitLoading,
      data: createVisitResult,
      reset: resetCreateVisit
    }
  ] = useCreateVisitMutation();

  // if visit is created with care flow, navigate to the care flow
  useEffect(() => {
    if (createVisitIsSuccess && createVisitResult.care_flow) {
      let careFlowLink = replace(
        CAREFLOW_PATH,
        ":visitId",
        createVisitResult?.visit_id
      );
      dispatch(setInitialState());

      navigate(careFlowLink);
    }
  }, [createVisitIsSuccess]);

  return (
    <>
      {FeatureFlags(user?.user_id).CARE_FLOWS && isSameDay && (
        <CustomTooltip
          title={
            currentVisit &&
            "Start Encounter is disabled due to having an encounter in progress already"
          }
          placement="bottom"
        >
          <div>
            <StyledButton
              variant="outlined"
              color="primary"
              disabled={!isFalsy(currentVisit) || isLoading || createVisitLoading}
              onClick={async () => {
                if (
                  FeatureFlags().CARE_FLOWS_DEVICE_SETUP &&
                  attendee?.status === MemberStatusEnum.PENDING
                ) {
                  setVisitModalAttendee({
                    attendee,
                    visitsRequest
                  });
                } else {
                  await createVisitMutation({
                    staff_id: user?.user_id,
                    patient_id: visitsRequest.patient_id,
                    body: {
                      ...visitsRequest,
                      staff_id: user?.user_id,
                      motivation_reason: VisitMotivationTypesEnum.APPOINTMENT
                    },
                    with_care_flow: true
                  });
                }
              }}
            >
              Start
            </StyledButton>
          </div>
        </CustomTooltip>
      )}
      {visitModalAttendee && (
        <VisitTypeModal
          attendee={visitModalAttendee?.attendee}
          visitsRequest={visitModalAttendee?.visitsRequest}
          staff_id={user?.user_id}
          onRequestClose={() => {
            setVisitModalAttendee(null);
          }}
        />
      )}
    </>
  );
};

export default StartCareFlow;
