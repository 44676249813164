import { ReactNode, CSSProperties } from "react";

import { Field } from "formik";
import MaskedInput from "react-text-mask";
import { styled } from "@mui/material/styles";
import { keyframes } from "@emotion/react";
import { Box, ButtonProps, Typography } from "@mui/material";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

import {
  formatName,
  isFalsy,
  prettyStatusString
} from "common/helpers/helpers";
import UserStatusEnum from "common/enums/UserStatusEnum";
import DeviceStatusEnum from "common/enums/DeviceStatusEnum";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import MemberStatusReasonEnum from "common/enums/MemberStatusReasonEnum";
import OrderStatusEnum from "common/enums/OrderStatusEnum";

import { ArrowBackIcon, HelpIcon } from "../assets/images/icons";
import { convertObjToJsx } from "../helpers/helpers";
import CustomTooltip from "./MUITooltip";
import { blue, gray } from "./colors";
import { Flexbox } from "./NewStyleComponents";
import { InfoOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import OrderTypeEnum from "common/enums/OrderTypeEnum";
import { RelationshipNoteCategoryEnum } from "common/types/RelationshipNoteType";
import { GoalCategoryEnum } from "common/types/GoalType";

const ComponentCardContainer = styled("div")`
  // border is 1px on each side
  width: 100%;
  position: relative;
  height: inherit;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TableComponentHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 95%;
  padding: 2.5%;
`;

const TableComponentContainer = styled("div")<{
  width?: string;
  containerMaxWidth?: string;
  margin?: string;
  children?: ReactNode | ReactNode[];
  hideBorder?: boolean;
  backgroundColor?: string;
  padding?: string;
}>`
  width: ${(props) => props.width ?? "90%"};
  padding: ${(props) => props.padding ?? "2.5%"};
  max-width: ${(props) => props.containerMaxWidth || "none"};
  background: ${(props) => props.backgroundColor ?? "#ffffff"};
  border: ${(props) => (props.hideBorder ? "none" : `1px solid ${gray[300]}`)};
  border-radius: 4px;
  overflow-x: auto;
  overflow-y: hidden;
  margin: ${({ margin }) => margin || "2.5%"};
`;

const PhoneNumberMask = styled(MaskedInput)`
  display: block;
  width: 95%;
  height: 50px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 4px;
  padding: clamp(5px, 3%, 15px);
`;

const rotate360 = keyframes`
from {
    transform: rotate(0turn);
}

to {
    transform: rotate(1turn);
}
`;

const PhoneNumber = styled("div")<{
  width?: string;
}>`
  width: ${(props) => props.width ?? "100%"};
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const DefaultTableCell = ({ children, ...props }) => {
  return (
    <Box {...props}>
      <Typography variant="body1" color="primary" fontSize={14}>
        {children}
      </Typography>
    </Box>
  );
};

const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DefaultTableHeader = styled("div")`
  font-size: 14px;
  text-color: ${(props) => props.theme.palette.secondary.main};
`;

const SubmitButtonContainer = styled("div")<{ width?: string }>`
  display: flex;
  position: relative;
  width: ${(props) => props.width || "auto"};
`;

const Spinner = styled("div")<{ loading?: string }>`
  position: absolute;
  left: 8px;
  animation: ${rotate360} 1s linear infinite;
  border: 4px solid ${blue[50]};
  border-top-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  visibility: ${(props) => (props.loading === "true" ? "visible" : "hidden")};
`;

const buttonStyles = {
  width: "100%",
  height: "50px",
  fontWeight: "600",
  fontSize: "13px",
  fontFamily: "Inter"
};

// ts is throwing an error, not sure how to fix it. adding 'any' for now. TBD fix
const TurqoiseButton = styled((props: ButtonProps) => (
  <LoadingButton variant="contained" color="primary" {...props}>
    {props.children}
  </LoadingButton>
))<{
  loading?: boolean;
}>(() => ({
  ...buttonStyles,
  textTransform: "none"
}));

const WhiteButton = styled((props: LoadingButtonProps) => (
  <LoadingButton {...props} variant="outlined" color="primary">
    {props.children}
  </LoadingButton>
))(() => ({
  ...buttonStyles,
  textTransform: "none"
}));

const DestructiveButton = styled((props: LoadingButtonProps) => (
  <LoadingButton {...props} variant="contained" color="error">
    {props.children}
  </LoadingButton>
))(() => ({
  ...buttonStyles,
  textTransform: "none"
}));

const CustomDatePicker = styled((props: any) => (
  <Box sx={{ verticalAlign: "top", position: "relative", width: "230px" }}>
    {props.value && (
      <Box
        sx={{
          fontSize: "14px",
          lineHeight: "1.4375em",
          fontWeight: "400",
          fontFamily: "Inter",
          padding: "0 8px",
          display: "block",
          transformOrigin: "top left",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "calc(133% - 32px)",
          position: "absolute",
          left: "0",
          top: "0",
          color: "#475467",
          transform: "translate(10px, -9px) scale(0.75)",
          transition:
            "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          zIndex: 1,
          pointerEvents: "auto",
          userSelect: "none",
          background: "white"
        }}
      >
        {props.label}
      </Box>
    )}
    <LoadingButton
      {...props}
      variant="outlined"
      color="secondary"
      fullWidth
      sx={{ backgroundColor: "white", justifyContent: "left !important" }}
      endIcon={
        <ArrowDropDownIcon
          sx={{ position: "absolute", right: "8px", top: "16px" }}
        />
      }
    >
      {props.value ?? props.label}
    </LoadingButton>
  </Box>
))(() => ({
  height: 53,
  textTransform: "none",
  borderColor: "#bbb",
  fontWeight: "normal"
}));

const FormLabel = styled("label")`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.palette.secondary.contrastText};
  display: block;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const TextFormField = styled(Field)<{ disableinput?: boolean }>`
  display: block;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 4px;
  padding: clamp(20px, 2%, 21px);
  font-size: 14px;

  &:disabled {
    color: ${(props) =>
      props?.disableinput
        ? props.theme.color.disabledInputFieldDark
        : props.theme.color.disabledInputFieldLight};
    background-color: ${(props) =>
      props?.disableinput ? "white" : props.theme.color.grey};
    cursor: not-allowed;
  }
`;

const TextSearchField = styled(Field)`
  display: block;
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  color: ${(props) => props.theme.color.grey700};
  background: ${blue[50]};
  border: 1px solid ${(props) => props.theme.color.grey100};
  border-radius: 4px;
  padding: 0 30px;
  font-family: ${(props) => props.theme.font.family};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;

// this component has the same styles as Theme h1
// We can't use Typography variant=h1 everywhere because only 1 h1 is allowed per page
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements#avoid_using_multiple_h1_elements_on_one_page
const ComponentHeader = styled("div")`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: ${gray[900]};
`;

const SuccessText = styled("div")<{ fontSize?: string; margin?: string }>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
  color: green;
  margin: ${(props) => (props.margin ? props.margin : 0)};
`;

const ErrorText = styled("div")<{
  fontSize?: string;
  marginLeft?: string;
  marginTop?: string;
}>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  color: ${(props) => props.theme.palette.error.main};
  overflow-wrap: anywhere;
  margin-left: ${(props) => props.marginLeft || "0px"};
  margin-top: ${(props) => props.marginTop || "0px"};
`;

const ErrorTextSpan = styled("span")<{ fontSize?: string }>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  color: ${(props) => props.theme.palette.error.main};
  overflow-wrap: anywhere;
`;

const ExternalLink = styled("a")`
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.main};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.main};
`;
type StatusEnum =
  | MemberStatusEnum
  | UserStatusEnum
  | DeviceStatusEnum
  | OrderStatusEnum
  | OrderTypeEnum
  | RelationshipNoteCategoryEnum
  | GoalCategoryEnum;

const handlePatientStatusColor = (status: StatusEnum) => {
  switch (status) {
    case OrderTypeEnum.DEVICE:
    case OrderStatusEnum.delivered:
    case MemberStatusEnum.ACTIVE:
    case UserStatusEnum.ACTIVE:
    case DeviceStatusEnum.ACTIVE:
      return "#0ABAB5";
    case OrderStatusEnum.in_fulfillment:
    case OrderStatusEnum.shipped:
    case OrderStatusEnum.accepted:
    case MemberStatusEnum.PENDING:
    case DeviceStatusEnum.SHIPPED:
    case DeviceStatusEnum.PENDING:
      return "#f9bf3b";
    case MemberStatusEnum.INACTIVE:
      return "#ff9470";
    case OrderTypeEnum.REFILL:
    case MemberStatusEnum.QUALIFIED:
    case MemberStatusEnum.ELIGIBLE:
    case MemberStatusEnum.REEVALUATING_PATIENT:
      return "#95a5a6";
    case OrderStatusEnum.returned:
    case OrderStatusEnum.backordered:
    case OrderStatusEnum.rejected:
    case OrderStatusEnum.lost:
    case MemberStatusEnum.CANCELED:
    case MemberStatusEnum.CANCELLATION_REQUESTED:
    case MemberStatusEnum.NEVER_ENGAGED:
    case UserStatusEnum.INACTIVE:
    case DeviceStatusEnum.INACTIVE:
    case DeviceStatusEnum.PENDING_DEACTIVATION:
    case DeviceStatusEnum.DEACTIVATED:
      return "#dd425a";
    case RelationshipNoteCategoryEnum.FAMILY:
    case GoalCategoryEnum.EXERCISE:
    case GoalCategoryEnum.HYPERTENSION:
      return "#215d86";
    case RelationshipNoteCategoryEnum.PERSONAL:
    case GoalCategoryEnum.LIFESTYLE:
    case GoalCategoryEnum.DIABETES:
      return "#d1a82d";
    case RelationshipNoteCategoryEnum.TRAVEL:
    case GoalCategoryEnum.MEDICATION:
      return "#6c55aa";
    case RelationshipNoteCategoryEnum.WORK:
    case GoalCategoryEnum.NUTRITION:
      return "#7db266";
    default:
      return "#95a5a6";
  }
};

const handlePatientStatusBGColor = (status: StatusEnum) => {
  switch (status) {
    case RelationshipNoteCategoryEnum.FAMILY:
      return "#c9daf8";
    case RelationshipNoteCategoryEnum.PERSONAL:
      return "#fff2cc";
    case RelationshipNoteCategoryEnum.TRAVEL:
      return "#d9d2e9";
    case RelationshipNoteCategoryEnum.WORK:
      return "#d9ead3";
    default:
      return handlePatientStatusColor(status) + "26";
  }
};

interface IStatusBadgeProps {
  style?: CSSProperties;
  status: StatusEnum;
  statusReason?: MemberStatusReasonEnum;
  hideDot?: boolean;
}
const StatusBadgeDiv = styled("div")`
  background: ${(props: IStatusBadgeProps) =>
    handlePatientStatusBGColor(props.status)};
  text-align: center;
  border-radius: 100px;
  float: left;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 15px;
  font-size: 14px;
  color: ${(props: IStatusBadgeProps) =>
    handlePatientStatusColor(props.status)};
`;

const StatusBadge = ({
  style,
  status,
  statusReason,
  hideDot = false
}: IStatusBadgeProps) => {
  if (isFalsy(status)) return null;

  const statusText = status.split("_");
  const statusReasonText = statusReason
    ? statusReason.split("_").join(" ")
    : "";
  return (
    <StatusBadgeDiv style={style} status={status} statusReason={statusReason}>
      {!hideDot && <StatusDot status={status} />}

      <Typography
        marginLeft={"3px"}
        fontWeight={"550"}
        textTransform={"capitalize"}
        data-testid={"memberStatus"}
        title={
          statusReasonText.length > 0
            ? `${statusText.join(" ")}: ${statusReasonText}`
            : statusText.join(" ")
        }
      >
        {prettyStatusString(status)}
      </Typography>
    </StatusBadgeDiv>
  );
};

const handleOrderStatusColor = (status: OrderStatusEnum) => {
  switch (status) {
    case OrderStatusEnum.started:
      return "#57bcca";
    case OrderStatusEnum.accepted:
      return "#57bcca";
    case OrderStatusEnum.delivered:
      return "#99c892";
    case OrderStatusEnum.delivered_lost:
      return "#cc4e5d";
    case OrderStatusEnum.lost:
      return "#cc4e5d";
    case OrderStatusEnum.returned:
      return "#cc4e5d";
    case OrderStatusEnum.in_fulfillment:
      return "#57bcca";
    case OrderStatusEnum.exception:
      return "#cc4e5d";
    case OrderStatusEnum.rejected:
      return "#cc4e5d";
    case OrderStatusEnum.deleted:
      return "#cc4e5d";
    default:
      return "#cc4e5d";
  }
};

const handleOrderStatusBGColor = (status: OrderStatusEnum) => {
  switch (status) {
    case OrderStatusEnum.shipped:
      return "#fdf1d9";
    case OrderStatusEnum.started:
      return "#dff4f7";
    case OrderStatusEnum.accepted:
      return "#dff4f7";
    case OrderStatusEnum.delivered:
      return "#e6f3e6";
    case OrderStatusEnum.delivered_lost:
      return "#f6e4e7";
    case OrderStatusEnum.lost:
      return "#f6e4e7";
    case OrderStatusEnum.returned:
      return "#f6e4e7";
    case OrderStatusEnum.in_fulfillment:
      return "#dff4f7";
    case OrderStatusEnum.exception:
      return "#f6e4e7";
    case OrderStatusEnum.rejected:
      return "#f6e4e7";
    case OrderStatusEnum.deleted:
      return "#f6e4e7";
    default:
      return "#f6e4e7";
  }
};
function getOrderStatusTitle(status: OrderStatusEnum) {
  switch (status) {
    case OrderStatusEnum.shipped:
      return "Order shipped with tracking details available";
    case OrderStatusEnum.started:
      return "Order placed and to be confirmed by warehouse";
    case OrderStatusEnum.accepted:
      return "Order confirmed and should ship within one business day";
    case OrderStatusEnum.delivered:
      return null;
    case OrderStatusEnum.delivered_lost:
      return "While order is delivered, patient reported they didn’t receive it";
    case OrderStatusEnum.returned:
      return "Order returned to sender, check tracking link for details";
    case OrderStatusEnum.in_fulfillment:
      return "Order is getting ready to be shipped";
    case OrderStatusEnum.exception:
      return null;
    case OrderStatusEnum.rejected:
      return null;
    case OrderStatusEnum.deleted:
      return null;
    default:
      return null;
  }
}

interface IOrderStatusBadgeProps {
  status: OrderStatusEnum;
}

const OrderStatusBadge = styled("div")`
  background: ${(props: IOrderStatusBadgeProps) =>
    handleOrderStatusBGColor(props.status)};
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 5px 15px;
  color: ${(props: IOrderStatusBadgeProps) =>
    handleOrderStatusColor(props.status)};
`;

const Dot = styled("div")`
  height: 6px;
  width: 6px;
  border-radius: 4px;
`;

const StatusDot = styled(Dot)<{
  status: StatusEnum;
}>`
  background: ${(props) => handlePatientStatusColor(props.status)};
`;

const OrderDot = styled(Dot)<{
  status: OrderStatusEnum;
}>`
  background: ${(props) => handleOrderStatusColor(props.status)};
`;

function OrderStatusComponent({
  status
}: Readonly<{
  status: OrderStatusEnum;
}>) {
  const tooltipText = getOrderStatusTitle(status);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {status && (
        <OrderStatusBadge status={status}>
          <OrderDot status={status} />
          {formatName(status)}&nbsp;
        </OrderStatusBadge>
      )}
      {tooltipText && (
        <CustomTooltip title={getOrderStatusTitle(status)}>
          <HelpIcon />
        </CustomTooltip>
      )}
    </div>
  );
}

const PatientAthenaText = styled("strong")`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #95a5a6;
  text-align: center;
`;

const PatientAthenaTextHeader = styled("strong")`
  color: #7f8c8d;
`;
export interface IHeaderProps {
  componentHeader: string;
  ComponentTooltip?: ReactNode;
}

const HeaderComponent = ({
  ComponentTooltip,
  componentHeader
}: IHeaderProps) => {
  let tooltip;

  if (ComponentTooltip) {
    tooltip = convertObjToJsx(ComponentTooltip);
  }
  return (
    <Flexbox gap="8px" alignItems="center" marginBottom={"20px"}>
      <Typography variant="h4" data-testid="ComponentHeaderText">
        {componentHeader}
      </Typography>
      {tooltip && (
        <CustomTooltip
          backgroundColor={gray[200]}
          placement="bottom"
          title={
            <Typography variant="body1" color="text.secondary" maxWidth="225px">
              {tooltip}
            </Typography>
          }
        >
          <InfoOutlined color="primary" />
        </CustomTooltip>
      )}
    </Flexbox>
  );
};

const BreadcrumbContainer = styled("div")<{ margin: string }>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin};
  cursor: pointer;
`;

const Breadcrumb = styled("div")`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => props.theme.palette.secondary.contrastText};
  text-decoration: none;
  display: flex;
`;

const BackButton = ({
  onClick,
  containerMargin = "36px"
}: {
  onClick: () => void;
  containerMargin?: string;
}) => {
  return (
    <BreadcrumbContainer margin={containerMargin}>
      <Breadcrumb onClick={onClick}>
        <ArrowBackIcon />
        Back
      </Breadcrumb>
    </BreadcrumbContainer>
  );
};

const Section = styled(Box)`
  margin: 36px 0px;
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 36px;
  padding-bottom: 50px;
  background: #ffffff;
  border: 1px solid #dadcdf;
  border-radius: 4px;
`;

export {
  ComponentCardContainer,
  CustomTooltip,
  TableComponentContainer,
  TableComponentHeader,
  TurqoiseButton,
  DestructiveButton,
  WhiteButton,
  CustomDatePicker,
  PhoneNumberMask,
  FormLabel,
  TextFormField,
  TextSearchField,
  ComponentHeader,
  HeaderComponent,
  PhoneNumber,
  DefaultTableCell,
  DefaultTableHeader,
  SubmitButtonContainer,
  Spinner,
  SuccessText,
  ErrorText,
  ErrorTextSpan,
  ExternalLink,
  StyledLink,
  StatusBadge,
  OrderStatusComponent,
  PatientAthenaText,
  PatientAthenaTextHeader,
  BackButton,
  Column,
  Section
};
