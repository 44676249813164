import { Tabs, Tab } from "@mui/material";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { useState } from "react";
import VisitContentField from "./VisitContentField";

interface IProps {
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
}

const VisitFieldTabGroup = ({ field, visit }: IProps) => {
  // Expected data structure:
  // Field = TAB_GROUP
  //      SubField = TAB
  //          SubField = other fields
  const [tabIndex, setTabIndex] = useState<number>(0);
  const handleTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const childrenTabs = field.subfields;

  const renderTabIndex = (index) => {
    const tab = field.subfields[index];
    return (
      <>
        {tab?.subfields?.map((item) => (
          <>
            <VisitContentField key={item.field_id} visit={visit} field={item} />
            <br />
          </>
        ))}
      </>
    );
  };

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={handleTabSelect}
        aria-label={field?.value}
      >
        {childrenTabs.map((item, i) => {
          return (
            <Tab key={i} label={item.value} style={{ fontWeight: "600" }} />
          );
        })}
      </Tabs>
      {renderTabIndex(tabIndex)}
      <br />
    </>
  );
};

export default VisitFieldTabGroup;
