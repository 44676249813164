import { styled, Typography } from "@mui/material";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";

interface IProps {
  field: CareFlowFieldType;
}

const QuoteTypography = styled(Typography)`
  text-align: left;
  font-style: italic;
`;

const QuoteContainer = styled("div")`
  display: flex;
  background-color: #f1f9ff;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
`;

const VisitFieldQuote = ({ field }: IProps) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>
      <QuoteContainer>
        <QuoteTypography variant="body1">
          {field.value}
        </QuoteTypography>
      </QuoteContainer>
    </div>
  );
};

export default VisitFieldQuote;
