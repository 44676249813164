import { ReactNode, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { blue, gray } from "../../styling/colors";
import { MenuItem, MenuList, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { Flexbox } from "../../styling/NewStyleComponents";
import replace from "lodash.replace";
import { useGetDevicesQuery } from "common/services/DevicesService";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import RolesEnum, {
  canSeeEncounters,
  canSeeMemberOrders
} from "common/enums/RolesEnum";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { isFalsy } from "common/helpers/helpers";
import FeatureFlags, {
  FeatureFlagsType,
  useFeatureFlags
} from "common/config/FeatureFlags";

const LayoutContainer = styled("div", {
  shouldForwardProp: (prop) => !["isLoggedIn"].includes(prop)
})<{ isLoggedIn?: boolean }>`
  width: 100%;
  display: flex;
  // TBD update background colors with ENG-3378
  // background: ${gray[100]};
  gap: 24px;
  height: max-content;
  overflow: hidden;
  flex-shrink: 1;
`;

const ChildContainer = styled.div`
  width: 100%;
  overflow: scroll;
`;

const SidebarContainer = styled.div`
  width: 160px;
  min-width: 160px;
  align-items: flex-start;
  max-height: fit-content;
  overflow: scroll;
`;

// We need this container here, use SidebarMenuContainer
// to keep MUI menu styling from overriding SidebarContainer styling
const SidebarMenuContainer = styled.div`
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid ${gray[300]};

  // stop menu item styles from overflowing the border radius https://stackoverflow.com/a/3724210
  overflow: hidden;
`;

const BaseMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => !["isActive", "padding"].includes(prop)
})<{
  isActive?: boolean;
  padding?: string | number;
}>`
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  padding: ${(props) => props.padding ?? "14px 16px;"}
    ${(props) =>
      props.isActive &&
      `background: ${blue[50]};
  color: ${blue[700]};`};
`;

const StyledReadingSubmenuItem = styled(BaseMenuItem)`
  padding: 0;
  height: 48px;
  ${(props) => props.isActive && `background: ${blue[50]}`}
`;

const StyledMenuItem = styled(BaseMenuItem, {
  shouldForwardProp: (prop) => !["isLastItem"].includes(prop)
})<{ isLastItem?: boolean }>`
  min-height: 48px;
  // if it is the last item, don't add a border
  border-bottom: ${(props) =>
    props.isLastItem ? "none" : `1px solid ${gray[300]}`};
`;

const menuItems = ({
  featureFlags,
  role
}: {
  featureFlags: FeatureFlagsType;
  role: RolesEnum;
}) => {
  return [
    // FEATURE FLAG: AUG-2024-CARE-FLOWS
    !featureFlags.CARE_FLOWS && {
      name: "Overview",
      route: "/members/memberId/:memberId/overview"
    },
    {
      name: "Appointments",
      route: "/members/memberId/:memberId/appointments"
    },
    { name: "Readings", route: "/members/memberId/:memberId/readings" },
    // { name: "Conditions", route: "/members/memberId/:memberId/conditions" },
    // { name: "Medications", route: "/members/memberId/:memberId/medications" },
    canSeeEncounters(role) && {
      name: "Encounters",
      route: "/members/memberId/:memberId/encounters"
    },

    // { name: "Labs", route: "/members/memberId/:memberId/labs" },
    // { name: "Lifestyle", route: "/members/memberId/:memberId/lifestyle" },
    canSeeMemberOrders(role) && {
      name: "Orders",
      route: "/members/memberId/:memberId/orders"
    },
    // { name: "Vaccines", route: "/members/memberId/:memberId/vaccines" },

    FeatureFlags().CARE_FLOWS_GOALS_RELATIONSHIP_NOTES_PROFILE && {
      name: "Goals",
      route: "/members/memberId/:memberId/goals"
    },

    // update this line below isLastItem={item.name === "Profile"}
    // if we add a different menu item after Profile
    { name: "Profile", route: "/members/memberId/:memberId/profile" }
  ];
};

interface IProps {
  memberId: string;
  hideReadings: boolean;
  children: ReactNode;
}

const readingsSubmenuItems = [
  { name: "BP", readingType: "blood_pressure" },
  { name: "Pulse", readingType: "pulse" },
  { name: "Glucose", readingType: "glucose" }
];

function ReadingsSubmenu({
  memberId,
  isActive
}: {
  memberId: string;
  isActive: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(isActive);

  const { data: devicesData, isFetching: devicesIsFetching } =
    useGetDevicesQuery(
      { memberId },
      {
        skip: memberId === undefined
      }
    );

  const submenuItems = useMemo(() => {
    if (!devicesData) {
      return [];
    }

    const hasOximeter = devicesData.find(
      (item) => item?.extra?.type === "Oximeter"
    );

    const hasWeightScale = devicesData.find(
      (item) => item?.extra?.type === "Weight Scale"
    );

    const finalSubmenuItems = [...readingsSubmenuItems];

    if (hasOximeter) {
      finalSubmenuItems.push({ name: "Oximeter", readingType: "oximeter" });
    }

    if (hasWeightScale) {
      finalSubmenuItems.push({ name: "Weight", readingType: "weight_scale" });
    }

    return finalSubmenuItems;
  }, [devicesData]);

  return (
    <StyledMenuItem
      padding={0}
      sx={{
        // this needs to be here - doesn't work in a styled component
        "&:hover": {
          backgroundColor: "transparent"
        }
      }}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <Flexbox flexDirection="column" width="100%">
        <Flexbox
          alignItems="center"
          justifyContent="space-between"
          // 16px horizontal margin * 2
          width="calc(100% - 32px)"
          margin="14px 16px"
        >
          <Typography>Readings</Typography>
          {isOpen && <ExpandMore />}
        </Flexbox>
        {isOpen && (
          <MenuList sx={{ padding: 0 }}>
            {devicesIsFetching && <LoadingFallback count={5} />}
            {devicesData &&
              submenuItems.map((item) => {
                const isActive = location.pathname.includes(item.readingType);
                return (
                  <StyledReadingSubmenuItem
                    padding={0}
                    id={item.name}
                    key={item.name}
                    isActive={isActive}
                    onClick={() => {
                      const finalRoute = replace(
                        `/members/memberId/:memberId/readings/${item.readingType}`,
                        ":memberId",
                        memberId
                      );
                      navigate(finalRoute);
                    }}
                  >
                    <Flexbox
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      padding="0 16px 0 32px"
                    >
                      <Typography data-testid={item.name}>
                        {item.name}
                      </Typography>
                      {isActive && <ChevronRight />}
                    </Flexbox>
                  </StyledReadingSubmenuItem>
                );
              })}
          </MenuList>
        )}
      </Flexbox>
    </StyledMenuItem>
  );
}

const MemberDetailsLayout = ({ memberId, hideReadings, children }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentRole } = useSelector((state: RootState) => state.auth);
  const featureFlags = useFeatureFlags();

  return (
    <LayoutContainer>
      <SidebarContainer>
        <SidebarMenuContainer>
          <MenuList sx={{ width: "100%", padding: "0" }}>
            {menuItems({
              featureFlags,
              role: currentRole
            }).map((item) => {
              if (isFalsy(item)) return null;
              const isOverview = item.name === "Overview";
              const isActive =
                location.pathname.includes(item.name.toLowerCase()) ||
                (isOverview && location.pathname.endsWith(memberId));

              if (item.name === "Readings") {
                if (hideReadings) {
                  return null;
                }
                return (
                  <ReadingsSubmenu
                    data-testid={item.name}
                    key={item.name}
                    memberId={memberId}
                    isActive={isActive}
                  />
                );
              }
              return (
                <StyledMenuItem
                  key={item.name}
                  data-testid={`${item.name}-sideMenu`}
                  isActive={isActive}
                  isLastItem={item.name === "Profile"}
                  onClick={() => {
                    const finalRoute = replace(
                      item.route,
                      ":memberId",
                      memberId
                    );
                    navigate(finalRoute);
                  }}
                >
                  <Flexbox
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    id={item.name}
                  >
                    <Typography data-testid={item.name}>{item.name}</Typography>
                    {isActive && <ChevronRight />}
                  </Flexbox>
                </StyledMenuItem>
              );
            })}
          </MenuList>
        </SidebarMenuContainer>
      </SidebarContainer>

      <ChildContainer>{children}</ChildContainer>
    </LayoutContainer>
  );
};

export default MemberDetailsLayout;
