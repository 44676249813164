import { Button, MenuItem, TextField, Typography } from "@mui/material";
import { Modal } from "../../components/Modal";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader
} from "../../styling/StyleModal";
import { RowContainer } from "../../pages/MemberDetails/StartIntake/StyledComponents";
import { useFormik } from "formik";
import {
  GoalCategoryEnum,
  GoalStatusEnum,
  GoalTypeEnum,
  getGoalCategoriesByType
} from "common/types/GoalType";
import { isFalsy, prettyStatusString } from "common/helpers/helpers";
import { LoadingButton } from "@mui/lab";
import { useUpsertGoalsMutation } from "common/services/MemberGoalsRelationshipService";

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  memberId: string;
  newGoalType: GoalTypeEnum;
}

interface FormType {
  type: GoalTypeEnum;
  category: GoalCategoryEnum;
  description: string;
  targetValue: string;
}

const AddGoalModal = ({
  isOpen,
  onRequestClose,
  memberId,
  newGoalType
}: IProps) => {
  const [upsertMutation, { isLoading }] = useUpsertGoalsMutation();

  const validate = (values) => {
    const errors = {};

    if (!values.type) {
      errors["type"] = "Mandatory field";
    }
    if (!values.category) {
      errors["category"] = "Mandatory field";
    }
    if (values.description < 4) {
      errors["description"] = "Mandatory field";
    }
    if (values.targetValue < 2) {
      errors["targetValue"] = "Mandatory field";
    }

    return errors;
  };

  const formik = useFormik<FormType>({
    validate,
    initialValues: {
      type: newGoalType,
      category: null,
      description: "",
      targetValue: ""
    },
    onSubmit: (values) => {
      upsertMutation({
        goals: [
          {
            goal_type: values.type,
            goal_description: values.description,
            goal_status: GoalStatusEnum.ACTIVE,
            target_value: values.targetValue,
            goal_category: values.category
          }
        ],
        member_id: memberId
      });
      handleClose();
    },
    enableReinitialize: true
  });

  const handleClose = () => {
    formik.resetForm();
    onRequestClose();
  };

  return (
    <Modal isOpen={isOpen} contentLabel="Add Modal Visible" modalHeight="75vh">
      <ModalHeader onRequestClose={handleClose}>Add new goal</ModalHeader>
      <ModalBody>
        <Typography variant={"h6"}>Goal type</Typography>
        <br />
        <TextField
          label="Select goal type"
          placeholder="Select"
          disabled
          value={formik.values.type ?? ""}
          error={!isFalsy(formik.errors["type"])}
          helperText={formik.errors["type"]}
        />
        <br />
        <Typography variant={"h6"}>Goal category</Typography>
        <br />
        <TextField
          label="Select goal category"
          select
          placeholder="Select"
          value={formik.values.category ?? ""}
          onChange={(event) =>
            formik.setFieldValue("category", event.target.value)
          }
          error={!isFalsy(formik.errors["category"])}
          helperText={formik.errors["category"]}
        >
          {getGoalCategoriesByType(formik.values.type).map((key) => (
            <MenuItem key={key} value={key}>
              {prettyStatusString(key)}
            </MenuItem>
          ))}
        </TextField>
        <br />
        <Typography variant={"h6"}>Goal description</Typography>
        <Typography variant={"caption"} style={{ fontStyle: "italic" }}>
          {newGoalType === GoalTypeEnum.CLINICAL &&
            `Example: Over the next 3 months, bring 7d average blood pressure down to 120/80 mmHg`}
          {newGoalType === GoalTypeEnum.MEMBER &&
            `Example: Take a 10 minute walk after dinner at least 5 nights a week`}
        </Typography>
        <br />
        <TextField
          label="Goal description"
          placeholder="Add description"
          multiline={true}
          rows={3}
          value={formik.values.description ?? ""}
          onChange={(event) =>
            formik.setFieldValue("description", event.target.value)
          }
          error={!isFalsy(formik.errors["description"])}
          helperText={formik.errors["description"]}
        />
        <br />
        <Typography variant={"h6"}>Target Values</Typography>
        <Typography variant={"caption"} style={{ fontStyle: "italic" }}>
          {newGoalType === GoalTypeEnum.CLINICAL &&
            `Example: 120/80 mmHg, 130 mg/DL`}
          {newGoalType === GoalTypeEnum.MEMBER && `Example: 5 nights a week`}
        </Typography>
        <br />
        <RowContainer gap={"10px"} alignItems={"center"}>
          <TextField
            label="Target"
            value={formik.values.targetValue ?? ""}
            onChange={(event) =>
              formik.setFieldValue("targetValue", event.target.value)
            }
            error={!isFalsy(formik.errors["targetValue"])}
            helperText={formik.errors["targetValue"]}
          />
        </RowContainer>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          <LoadingButton
            variant="contained"
            disabled={!formik.isValid}
            onClick={() => formik.handleSubmit()}
            loading={isLoading}
          >
            Create and add goal
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooterButtons>
      </ModalFooter>
    </Modal>
  );
};

export default AddGoalModal;
